import { ActionType,  PayloadAction } from 'typesafe-actions';
import {createAction} from '../utils/actions/action-creators.util';
import { S3_SIGN_URL, S3_SIGN_URL_ERROR, S3_SIGN_URL_SUCCESS } from '../constants/s3.constants';
import { S3BucketKey } from '../reducers/s3.reducer';

export const s3Actions = {
	/**
	 * S3_SIGN_URL
	 */
    signUrl: (request: S3BucketKey): PayloadAction<typeof S3_SIGN_URL, {request: typeof request}> => createAction(S3_SIGN_URL, { request }),
    signUrlError: (error: Error, request: S3BucketKey): PayloadAction<typeof S3_SIGN_URL_ERROR, {error: typeof error, request: typeof request }> =>
		createAction(S3_SIGN_URL_ERROR, {error, request}),
    signUrlSuccess: (signedUrl: string, request: S3BucketKey): PayloadAction<typeof S3_SIGN_URL_SUCCESS, { request: typeof request, signedUrl: typeof signedUrl }> =>
		createAction(S3_SIGN_URL_SUCCESS, { request, signedUrl }),     
};

export type s3Actions = ActionType<typeof s3Actions>;