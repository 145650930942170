import {combineReducers} from 'redux';
import {StateType} from 'typesafe-actions';

import {DeepReadonlyObject} from '../types/reducers/DeepReadonly';
import {OperationState} from '../types/reducers/OperationStatus';
import {getStateError, getStateInProgress, getStateSuccess} from '../utils/reducers/state.util';
import { NullableState } from '../types/reducers/NullableState';
import { s3Actions } from '../actions/s3.actions';
import { S3_SIGN_URL, S3_SIGN_URL_ERROR, S3_SIGN_URL_SUCCESS } from '../constants/s3.constants';

export type S3State = {
	signedUrls: {
        [key: string]: OperationState<string>
    };
};

export type S3BucketKey = { bucket: string, key: string };

type S3StateNullable = NullableState<S3State>;
type S3StateReadonly = DeepReadonlyObject<S3StateNullable>;

export const initialS3State: S3StateNullable = {
	signedUrls: {}
};

export const createSignedUrlStateKey = ({ key, bucket }: S3BucketKey) => {
    return `${bucket}/${key}`;
}

export const s3Reducers = combineReducers<S3StateReadonly, s3Actions>({
	signedUrls: (state = initialS3State.signedUrls, action) => {
        switch (action.type) {
            case S3_SIGN_URL: {
                const { request } = action.payload;
                return {
                    ...state,
                    [createSignedUrlStateKey(request)]: getStateInProgress()
                };
            }
            case S3_SIGN_URL_SUCCESS: {
                const { request, signedUrl } = action.payload;
                return {
                    ...state,
                    [createSignedUrlStateKey(request)]: getStateSuccess(signedUrl)
                };
            }
            case S3_SIGN_URL_ERROR: {
                const {error, request} = action.payload;
    
                return {
                    ...state,
                    [createSignedUrlStateKey(request)]: getStateError(error)
                }
            }
            default:
                return state;
            }
	}
});

export type s3Reducers = StateType<typeof s3Actions>;