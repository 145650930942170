import {all, call, put, takeEvery} from 'redux-saga/effects';
import {Auth} from 'aws-amplify';
import { authActions } from '../actions/auth.actions';
import { iotActions } from '../actions/iot.actions';
import { LOAD_AUTH } from '../constants/auth.constants';

export const authSagas = () => {

  function* loadAuthSaga(action: ReturnType<typeof authActions['loadAuth']>) {
    try {
      //the user should always be authenticated when we are here
      //the withAuthenticator HOC we use to wrap the App (see bottom of file) means the component does not mount without a valid session and this code runs in a side effect
      //however, we will make sure by fetching the user and exiting if we catch an error
      yield call(
        Auth.currentAuthenticatedUser.bind(Auth)
      );

      //if we're here then we are good to fetch creds
      const credentials = yield call(
        Auth.currentCredentials.bind(Auth)
      );
      
      yield put(authActions.loadSuccess(credentials));

      yield put(iotActions.connect())
      
    } catch (error) {
      console.error(`An error occurred while setting credentials for services`, error); 
      yield put(authActions.loadAuthError(error));
    }
  }

  return {
    watcher: function* () {
      yield all([takeEvery(LOAD_AUTH, loadAuthSaga)]);
    }
  }
};