import logoBlack from "../assets/logo-black.svg";
import logo from "../assets/logo.svg";
import olympicsLogo from "../assets/olympic-logo.svg";
import {Link, useLocation} from "react-router-dom";
import React from "react";
import './NavBar.css';

const navItems = [
  {
    path: '/',
    name:  'Home',
  },
  {
    path: '/settings',
    name:  'Settings',
  },
  {
    path: '/search',
    name:  'Search',
  },
]
const now = Date.now()
const endOfOlympics = 1645398000000;

export default function NavBar() {
  const location = useLocation();

  const renderedItems = () => {
    return navItems.map((item, i) => {
      return (
        <div className="navItem">
          <Link className={(location.pathname === item.path ? "active" : '')} to={item.path}>{item.name}</Link>
        </div>
      )
    })
  };
  return (
    <header className={`navBar ${now < endOfOlympics && "olympics"}`}>
      {now < endOfOlympics && <img src={olympicsLogo} className="olympicsLogo" alt="logo" />}
      <img src={now < endOfOlympics ? logoBlack : logo} className="logo" alt="logo" />
      {renderedItems()}
    </header>
  );
}
