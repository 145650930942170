/**
 * LOAD_AUTH
 */
export const LOAD_AUTH = 'AUTH.LOAD_AUTH';
export const LOAD_AUTH_SUCCESS = 'AUTH.LOAD_AUTH_SUCCESS';
export const LOAD_AUTH_ERROR = 'AUTH.LOAD_AUTH_ERROR';

/**
 * CLEAR_AUTH
 */
 export const CLEAR_AUTH = 'AUTH.CLEAR_AUTH';
 export const CLEAR_AUTH_SUCCESS = 'AUTH.CLEAR_AUTH_SUCCESS';
 export const CLEAR_AUTH_ERROR = 'AUTH.CLEAR_AUTH_ERROR';