import {all, call, put, take, takeEvery} from 'redux-saga/effects';
import AWSIoTData from "aws-iot-device-sdk";
import SolutionManifest from "../../services/SolutionManifest";
import { apiActions } from '../actions/api.actions';
import { iotActions } from '../actions/iot.actions';
import { IOT_CONNECT, IOT_DISCONNECT, IOT_REGISTER_LISTENER} from '../constants/iot.constants';
import {randomHexstring} from "../../util/formatUtils";
import { Auth } from '@aws-amplify/auth';
import { store } from '../store';

export const iotSagas = () => {
  let client;
  let clientId;

  async function reconnect(cred?) {
    try {
      const credentials = await Auth.currentCredentials();

      const {
        accessKeyId = '',
        secretAccessKey = '',
        sessionToken = '',
      } = cred || credentials;
      
      await client.updateWebSocketCredentials(accessKeyId, secretAccessKey, sessionToken);
    } catch (e) {
      e.message = `[ERR]: iot.reconnect: ${encodeURIComponent(e.message)}`;
      console.error(e.message);
    }
  }

  function* connectSaga() {
    try {
      try {
        yield put(apiActions.attachIot());
        yield take([apiActions.attachIotError({} as Error).type, apiActions.attachIotSuccess({}).type]);
  
        const user = yield call(Auth.currentUserInfo.bind(Auth));
        const username = (user || {}).username || 'anonymous';
        clientId = `${username}-${randomHexstring()}`;
        client = AWSIoTData.device({
          region: SolutionManifest.Region,
          host: SolutionManifest.IoT.Host,
          clientId: clientId,
          protocol: 'wss',
          maximumReconnectTimeMs: 8000,
          debug: false,
          accessKeyId: '',
          secretKey: '',
          sessionToken: '',
        });
      } catch (e) {
        e.message = `IotSubscriber.connect: ${encodeURIComponent(e.message)}`;
        console.error(e.message);
        throw e;
      }

      const connectHandler = () => {
        console.log(`${clientId} connected to IoT`);
        client.unsubscribe(SolutionManifest.IoT.Topic);
        client.subscribe(SolutionManifest.IoT.Topic);
      };

      const reconnectHandler = () => {
        console.log(`reconnecting ${clientId}...`);
        reconnect();
      }

      const errorHandler = (e) => {
        console.log(`error: iot.error: ${e}`);
      };

      const messageHandler = (topic, payload) => {
        const data = JSON.parse(payload.toString());
        if (data.service === 'languageid') {
          if ((data.action === 'CREATE') || (data.action === 'INSERT') || (data.action === 'UPDATE')) {
            store.dispatch(apiActions.addDetectionResult(data));
          }
        } else if (data.service === 'schedule') {
          if (data.action === 'REMOVE') {
            store.dispatch(apiActions.removeSchedule(data));
          } else if ((data.action === 'CREATE') || (data.action === 'INSERT')) {
            store.dispatch(apiActions.addSchedule(data));
          }  else if (data.action === 'MODIFY') {
            store.dispatch(apiActions.updateSchedule(data));
          }
        }
      }
  
      client.off('connect', connectHandler);
      client.on('connect', connectHandler);
  
      client.off('reconnect', reconnectHandler);
      client.on('reconnect', reconnectHandler);

      client.off('error', errorHandler);
      client.on('error', errorHandler);

      client.off('message', messageHandler);
      client.on('message', messageHandler);

      console.info('Successfully connected to IoT', client); 
      yield put(iotActions.connectSuccess());
    }
    catch (err) {
      console.error(`An error occurred while connecting to IoT`, err); 
      yield put(iotActions.connectError(err));
    }
  }

  function* disconnectSaga(action: ReturnType<typeof iotActions['disconnect']>) {

    try {
      if (client) {
        client.unsubscribe(SolutionManifest.IoT.Topic, () => {});
        client.end(true);
      }

      console.info('Successfully disconnected rom IoT', client); 
      yield put(iotActions.disconnectSuccess());
    }
    catch (err) {
      console.error(`An error occurred while disconnecting to IoT`, err); 
      yield put(iotActions.disconnectError(err));
    }
  }

  function* registerListenerSaga(action: ReturnType<typeof iotActions['registerListener']>) {

    try {
      const { listener, stateRef, type } = action.payload;

      const handler = (topic, payload) => {
        const data = JSON.parse(payload.toString());
        listener(data, stateRef);
      };
      client[type === 'subscribe' ? 'on' : 'off']('message', handler);

      console.info('Successfully registered the listener', client); 
      yield put(iotActions.registerListenerSuccess());
    }
    catch (err) {
      console.error(`An error occurred while registering the listener`, err); 
      yield put(iotActions.registerListenerError(err));
    }
  }

  return {
    watcher: function* () {
      yield all([takeEvery(IOT_CONNECT, connectSaga)]);
      yield all([takeEvery(IOT_DISCONNECT, disconnectSaga)]);
      yield all([takeEvery(IOT_REGISTER_LISTENER, registerListenerSaga)]);
    }
  }
}