import Stream from "../types/Stream";
import { withErrorBoundary } from "react-error-boundary";
import ErrorFallback from "./ErrorFallback";
import { useSelector } from "react-redux";
import { apiSelectors } from "../store/selectors/api.selectors";
import { CHANNEL_MAPPING } from "../constants";

export default withErrorBoundary(FilterRow, {
  FallbackComponent: ErrorFallback,
  onError(error, info) {
    console.log(error);
  },
})

function FilterRow (props: {
  selection: String[],
  onChangeFilter: Function,
  stream: Stream,
  handleStatuses: Function
}) {
  const stream = props.stream;
  const handleStatuses = props.handleStatuses;

  const bestProgramMatch = () => {
    const now = Date.now();
    /* find current program */
    let program = stream.schedules.find(x =>
      x.actualStartTime <= now && x.actualEndTime >= now);
    if (program) {
        return program;
    }
    /* find next available program */
    program = stream.schedules.find(x =>
      x.actualStartTime > now);
    if (program) {
        return program;
    }
    /* find last program that has already aired */
    return stream.schedules[stream.schedules.length - 1];
  }

  let s = bestProgramMatch();
  let scheduleDetails;
  scheduleDetails = useSelector(apiSelectors.scheduleDetailsResult(s.assetId));
  const results = useSelector(apiSelectors.todaySchedulesDetectionResults);

  // Handling overlapping scenarios
  if(results && results[stream.streamId] && results[stream.streamId].assetId && scheduleDetails) {
    if(results[stream.streamId].assetId != scheduleDetails.assetId){
      const now = Date.now();
      const isLiveDetectionResults = results[stream.streamId].actualStartTime <= now && results[stream.streamId].actualEndTime >= now;
      const isLiveScheduledDetails = scheduleDetails.actualStartTime <= now && scheduleDetails.actualEndTime >= now;
      if(isLiveDetectionResults && isLiveScheduledDetails) {
        console.log("Both live")

        //  In case the there are overlapping programs, then we choose the program with earlier startTime
        const earlierStartTimeProgram = results[stream.streamId].actualStartTime < scheduleDetails.actualStartTime ? results[stream.streamId] : scheduleDetails;
        if (earlierStartTimeProgram.actualStartTime <= now && earlierStartTimeProgram.actualEndTime >= now) {
          scheduleDetails = earlierStartTimeProgram;

      // If the endTime of the detection results asset is greater than current time i.e. it is live, then choose it
      }} else if (results[stream.streamId].actualEndTime > now){
        scheduleDetails = results[stream.streamId];
      }
    }
  }

  let startTime, endTime;
  if(scheduleDetails){
    startTime = scheduleDetails["actualStartTime"];
    endTime = scheduleDetails["actualEndTime"];
  } else {
    startTime = s.actualStartTime;
    endTime = s.actualEndTime;
  }

  const now = Date.now();
  let liveStatus = '';
  if (now > endTime)
    liveStatus = 'END';
  else if(now < startTime)
    liveStatus = 'SCHEDULED';
  else if (stream.broadcastType && s.broadcastType === 'Replay')
    liveStatus = 'REPLAY';
  else if (now >= startTime && now <= endTime)
    liveStatus = 'LIVE';

  const streamLabel = CHANNEL_MAPPING[s.streamId] || s.streamId;
  handleStatuses(s.streamId, liveStatus);

  return (
    <span> {streamLabel} {liveStatus == 'END' &&
      <span className="badge bg-dark position-absolute top-0 end-0">End</span>}{liveStatus == 'SCHEDULED' &&
      <span className="badge bg-warning text-dark  position-absolute top-0 end-0">Scheduled</span>}{liveStatus == 'LIVE' &&
      <span className="badge bg-success position-absolute top-0 end-0">Live</span>}{liveStatus == 'REPLAY' &&
      <span className="badge bg-primary text-light position-absolute top-0 end-0">Replay</span>}
    </span>
  );
}
