import { Action, ActionType,  PayloadAction } from 'typesafe-actions';
import {createAction} from '../utils/actions/action-creators.util';
import {Schedule} from '../../types/Stream';
import { APIState } from '../reducers/api.reducer';
import {
  API_ADD_DETECTION_RESULT,
  API_ADD_SCHEDULE,
  API_ATTACH_IOT,
  API_ATTACH_IOT_ERROR,
  API_ATTACH_IOT_SUCCESS,
  API_GET_SCHEDULES,
  API_GET_SCHEDULES_ERROR,
  API_GET_SCHEDULES_SUCCESS,
  API_GET_SCHEDULE_DETAILS,
  API_GET_SCHEDULE_DETAILS_ERROR,
  API_GET_SCHEDULE_DETAILS_SUCCESS,
  API_GET_SCHEDULE_RECORDS,
  API_GET_SCHEDULE_RECORDS_ERROR,
  API_GET_SCHEDULE_RECORDS_SUCCESS,
  API_GET_TODAY_SCHEDULES,
  API_GET_TODAY_SCHEDULES_ERROR,
  API_GET_TODAY_SCHEDULES_SUCCESS,
  API_POST_CONFIG,
  API_POST_CONFIG_ERROR,
  API_POST_CONFIG_SUCCESS,
  API_SEARCH,
  API_SEARCH_ERROR,
  API_SEARCH_SUCCESS,
  API_REMOVE_SCHEDULE,
  API_UPDATE_SCHEDULE,
  API_GET_ALARM_PRESETS, API_GET_ALARM_PRESETS_ERROR, API_GET_ALARM_PRESETS_SUCCESS
} from '../constants/api.constants';
import DetectionResult from '../../types/DetectionResult';
import Sport from '../../types/Sport';
import AlarmPreset from "../../types/AlarmPreset";

type GetScheduleRecordsOptions = { startTime: number, endTime: number, maxResults: number; nextToken?: string; };

export const apiActions = {
	/**
	 * GET_SCHEDULE_RECORDS
	 */
    getScheduleRecords: (request: {assetId: string, type: 'home' | 'details', options: GetScheduleRecordsOptions}): PayloadAction<typeof API_GET_SCHEDULE_RECORDS, { request: typeof request }> => createAction(API_GET_SCHEDULE_RECORDS, { request }),
	getScheduleRecordsError: (request: {assetId: string, type: 'home' | 'details', options: GetScheduleRecordsOptions}, error: Error): PayloadAction<typeof API_GET_SCHEDULE_RECORDS_ERROR, {request: typeof request, error: typeof error}> =>
		createAction(API_GET_SCHEDULE_RECORDS_ERROR, {request, error}),
    getScheduleRecordsSuccess: (request: {assetId: string, type: 'home' | 'details', options: GetScheduleRecordsOptions}, response: APIState['scheduleRecords'][string], isNextPage?: boolean): PayloadAction<typeof API_GET_SCHEDULE_RECORDS_SUCCESS, {request: typeof request, response: typeof response, isNextPage: typeof isNextPage}> =>
		createAction(API_GET_SCHEDULE_RECORDS_SUCCESS, {request, response, isNextPage}),

    /**
	 * POST_CONFIG
	 */
    postConfig: (request: {assetId: string, action: 'mute' | 'unmute' | 'pause' | 'resume'}): PayloadAction<typeof API_POST_CONFIG, { request: typeof request }> => createAction(API_POST_CONFIG, { request }),
    postConfigError: (error: Error): PayloadAction<typeof API_POST_CONFIG_ERROR, {error: typeof error}> =>
        createAction(API_POST_CONFIG_ERROR, {error}),
    postConfigSuccess: (): Action<typeof API_POST_CONFIG_SUCCESS> =>
        createAction(API_POST_CONFIG_SUCCESS),

    /**
	 * API_GET_SCHEDULES
	 */
    getSchedules: (request: {startTime: string, endTime: string}): PayloadAction<typeof API_GET_SCHEDULES, { request: typeof request }> => createAction(API_GET_SCHEDULES, { request }),
    getSchedulesError: (error: Error): PayloadAction<typeof API_GET_SCHEDULES_ERROR, {error: typeof error}> =>
        createAction(API_GET_SCHEDULES_ERROR, {error}),
    getSchedulesSuccess: (response: { schedules: Schedule[] }): PayloadAction<typeof API_GET_SCHEDULES_SUCCESS, {response: typeof response}> =>
        createAction(API_GET_SCHEDULES_SUCCESS, {response}),

    /**
	 * API_GET_TODAY_SCHEDULES
	 */
    getTodaySchedules: (): Action<typeof API_GET_TODAY_SCHEDULES> => createAction(API_GET_TODAY_SCHEDULES),
    getTodaySchedulesError: (error: Error): PayloadAction<typeof API_GET_TODAY_SCHEDULES_ERROR, {error: typeof error}> =>
        createAction(API_GET_TODAY_SCHEDULES_ERROR, {error}),
    getTodaySchedulesSuccess: (response: { items: Schedule[] }): PayloadAction<typeof API_GET_TODAY_SCHEDULES_SUCCESS, {response: typeof response}> =>
        createAction(API_GET_TODAY_SCHEDULES_SUCCESS, {response}),

    /**
	 * API_ADD_SCHEDULE
	 */
    addSchedule: (scheduleToAdd: Schedule): PayloadAction<typeof API_ADD_SCHEDULE, { scheduleToAdd: typeof scheduleToAdd }> => createAction(API_ADD_SCHEDULE, { scheduleToAdd }),

    /**
	 * API_UPDATE_SCHEDULE
	 */
     updateSchedule: (scheduleToUpdate: Schedule): PayloadAction<typeof API_UPDATE_SCHEDULE, { scheduleToUpdate: typeof scheduleToUpdate }> => createAction(API_UPDATE_SCHEDULE, { scheduleToUpdate }),

    /**
   * API_REMOVE_SCHEDULE
   */
    removeSchedule: (scheduleToRemove: Schedule): PayloadAction<typeof API_REMOVE_SCHEDULE, { scheduleToRemove: typeof scheduleToRemove }> => createAction(API_REMOVE_SCHEDULE, { scheduleToRemove }),

    /**
	 * API_ADD_DETECTION_RESULT
	 */
    addDetectionResult: (detectionResultToAdd: Partial<DetectionResult>): PayloadAction<typeof API_ADD_DETECTION_RESULT, { detectionResultToAdd: typeof detectionResultToAdd }> => createAction(API_ADD_DETECTION_RESULT, { detectionResultToAdd }),

    /**
	 * API_GET_SCHEDULE_DETAILS
	 */
    getScheduleDetails: (request: { assetId: string }): PayloadAction<typeof API_GET_SCHEDULE_DETAILS, { request: typeof request }> => createAction(API_GET_SCHEDULE_DETAILS, { request }),
    getScheduleDetailsError: (assetId: string, error: Error): PayloadAction<typeof API_GET_SCHEDULE_DETAILS_ERROR, { assetId: typeof assetId; error: typeof error}> =>
        createAction(API_GET_SCHEDULE_DETAILS_ERROR, {assetId, error}),
    getScheduleDetailsSuccess: (assetId: string, response: Schedule): PayloadAction<typeof API_GET_SCHEDULE_DETAILS_SUCCESS, {response: typeof response, assetId: typeof assetId}> =>
        createAction(API_GET_SCHEDULE_DETAILS_SUCCESS, {assetId, response}),

    /**
	 * API_ATTACH_IOT
	 */
    attachIot: (): Action<typeof API_ATTACH_IOT> => createAction(API_ATTACH_IOT),
    attachIotError: (error: Error): PayloadAction<typeof API_ATTACH_IOT_ERROR, {error: typeof error}> =>
        createAction(API_ATTACH_IOT_ERROR, {error}),
    attachIotSuccess: (response: any): PayloadAction<typeof API_ATTACH_IOT_SUCCESS, { response: typeof response }> =>
        createAction(API_ATTACH_IOT_SUCCESS, { response }),

    /**
	 * API_SEARCH
	 */
    search: (request: { options: unknown }): PayloadAction<typeof API_SEARCH, { request: typeof request }> => createAction(API_SEARCH, { request }),
    searchError: (error: Error): PayloadAction<typeof API_SEARCH_ERROR, {error: typeof error}> =>
        createAction(API_SEARCH_ERROR, {error}),
    searchSuccess: (response: { items: unknown[]; nextToken?: string }): PayloadAction<typeof API_SEARCH_SUCCESS, {response: typeof response}> =>
        createAction(API_SEARCH_SUCCESS, {response}),

    /**
     * API_GET_ALARM_PRESETS
     */
    getAlarmPresets: (): PayloadAction<typeof API_GET_ALARM_PRESETS, {}> => createAction(API_GET_ALARM_PRESETS, { }),
    getAlarmPresetsError: (error: Error): PayloadAction<typeof API_GET_ALARM_PRESETS_ERROR, {error: typeof error}> =>
      createAction(API_GET_ALARM_PRESETS_ERROR, {error}),
    getAlarmPresetsSuccess: (response: { sports: Sport[], presets: AlarmPreset[]}): Action<typeof API_GET_ALARM_PRESETS_SUCCESS> =>
      createAction(API_GET_ALARM_PRESETS_SUCCESS, {response}),
};

export type apiActions = ActionType<typeof apiActions>;