import { OperationState, OperationStatus } from '../../types/reducers/OperationStatus';

export const getLoadingStatusInitial = <Err>(): OperationStatus<Err> => ({
	didSucceed: false,
	isLoading: false
});

export const getLoadingStatusInProgress = <Err>(): OperationStatus<Err> => ({
	didSucceed: false,
	isLoading: true
});

export const getLoadingStatusSuccess = <Err>(): OperationStatus<Err> => ({
	didSucceed: true,
	isLoading: false
});

export const getLoadingStatusError = <Err>(error: Err): OperationStatus<Err> => ({
	didSucceed: false,
	error,
	isLoading: false
});

export const getStateInitial = <Current, Err = Error>(initialValue?: Current | undefined): OperationState<Current, Err> => ({
	current: initialValue,
	status: getLoadingStatusInitial<Err>()
});

export const getStateInProgress = <Current, Err = Error>(): OperationState<Current, Err> => ({
	current: undefined,
	status: getLoadingStatusInProgress<Err>()
});
export const getStateSuccess = <Current, Err = Error>(
	current: Current
): OperationState<Current, Err> => ({
		current,
		status: getLoadingStatusSuccess<Err>()
	});

export const getStateError = <Current, Err = Error>(error: Err): OperationState<Current, Err> => ({
	current: undefined,
	status: getLoadingStatusError<Err>(error)
});
