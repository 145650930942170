/**
 * API_GET_SCHEDULE_RECORDS
 */
export const API_GET_SCHEDULE_RECORDS = 'API.GET_SCHEDULE_RECORDS';
export const API_GET_SCHEDULE_RECORDS_SUCCESS = 'API.GET_SCHEDULE_RECORDS_SUCCESS';
export const API_GET_SCHEDULE_RECORDS_ERROR = 'API.GET_SCHEDULE_RECORDS_ERROR';

/**
 * API_POST_CONFIG
 */
export const API_POST_CONFIG = 'API.POST_CONFIG';
export const API_POST_CONFIG_SUCCESS = 'API.POST_CONFIG_SUCCESS';
export const API_POST_CONFIG_ERROR = 'API.POST_CONFIG_ERROR';

/**
 * API_GET_SCHEDULES
 */
export const API_GET_SCHEDULES = 'API.GET_SCHEDULES';
export const API_GET_SCHEDULES_SUCCESS = 'API.GET_SCHEDULES_SUCCESS';
export const API_GET_SCHEDULES_ERROR = 'API.GET_SCHEDULES_ERROR';

/**
 * API_GET_TODAY_SCHEDULES
 */
export const API_GET_TODAY_SCHEDULES = 'API.GET_TODAY_SCHEDULES';
export const API_GET_TODAY_SCHEDULES_SUCCESS = 'API.GET_TODAY_SCHEDULES_SUCCESS';
export const API_GET_TODAY_SCHEDULES_ERROR = 'API.GET_SCHEDULES_ERROR';

/**
 * API_ADD_SCHEDULE
 */
export const API_ADD_SCHEDULE = 'API.ADD_SCHEDULE';

/**
 * API_UPDATE_SCHEDULE
 */
 export const API_UPDATE_SCHEDULE = 'API.UPDATE_SCHEDULE';

/**
 * API_REMOVE_SCHEDULE
 */
export const API_REMOVE_SCHEDULE = 'API.REMOVE_SCHEDULE';

/**
 * API_ADD_DETECTION_RESULT
 */
 export const API_ADD_DETECTION_RESULT = 'API.ADD_DETECTION_RESULT';

/**
 * API_GET_SCHEDULE_DETAILS
 */
export const API_GET_SCHEDULE_DETAILS = 'API.GET_SCHEDULE_DETAILS';
export const API_GET_SCHEDULE_DETAILS_SUCCESS = 'API.GET_SCHEDULE_DETAILS_SUCCESS';
export const API_GET_SCHEDULE_DETAILS_ERROR = 'API.GET_SCHEDULE_DETAILS_ERROR';

/**
 * API_ATTACH_IOT
 */
export const API_ATTACH_IOT = 'API.ATTACH_IOT';
export const API_ATTACH_IOT_SUCCESS = 'API.ATTACH_IOT_SUCCESS';
export const API_ATTACH_IOT_ERROR = 'API.ATTACH_IOT_ERROR';

/**
 * API_SEARCH
 */
export const API_SEARCH = 'API.SEARCH';
export const API_SEARCH_SUCCESS = 'API.SEARCH_SUCCESS';
export const API_SEARCH_ERROR = 'API.SEARCH_ERROR';

/**
 * API_GET_ALARM_PRESETS
 */
export const API_GET_ALARM_PRESETS = 'API.GET_ALARM_PRESETS';
export const API_GET_ALARM_PRESETS_SUCCESS = 'API.GET_ALARM_PRESETS_SUCCESS';
export const API_GET_ALARM_PRESETS_ERROR = 'API.GET_ALARM_PRESETS_ERROR';