import {createSelector} from 'reselect';
import Stream, { Schedule } from '../../types/Stream';
import {RootState} from '../reducers/root.reducer';
import {
	createOperationErrorSelector,
	createOperationResultSelector,
	createOperationStatusSelector
} from '../utils/selectors/selectors.util'
import AlarmPreset from "../../types/AlarmPreset";
import Sport from '../../types/Sport';

const apiSelector = (state: RootState) => {
	return state?.api;
};

const apiScheduleRecordsResultSelector = (key: string) => createOperationResultSelector(createSelector(apiSelector, (state) => { return state?.scheduleRecords?.[key]! }));

const apiScheduleRecordStatusSelector = (key: string) => createOperationStatusSelector(createSelector(apiSelector, (state) => { return state?.scheduleRecords?.[key]! }));

const apiScheduleRecordsItemsSelector = (key: string) => createSelector(apiScheduleRecordsResultSelector(key), (state) => {
	return state?.records || [];
});

const apiTodaySchedulesOperationSelector = createSelector(apiSelector, (state) => {
	return state?.todaySchedules;
});

const apiScheduleDetailsSelector = createSelector(apiSelector, (state) => {
	return state?.scheduleDetails;
});

const apiScheduleDetailsAssetSelector = (assetId: string) => createSelector(apiScheduleDetailsSelector, (state) => {
	return state?.[assetId]!;
});


const apiScheduleDetailsAssetResultSelector = (assetId: string) => createOperationResultSelector(apiScheduleDetailsAssetSelector(assetId))

export const selectStreamsFromSchedule = (schedules?: Schedule[]) => {
	
	if (schedules?.length) {
		schedules.sort((a, b) => { return a.actualStartTime-b.actualStartTime})
        // @ts-ignore
        let allStreamIds = [...new Set(schedules.map(i => i.streamId))];
        const fetchedStreams = allStreamIds.map((s) => {
          let schedulesFiltered = schedules.filter((i) => {
            return i.streamId === s;
          }) || [];
          return {streamId: s, schedules: schedulesFiltered};
        });
        return fetchedStreams.sort((a, b) => b.streamId - a.streamId) || [];
	}
}

const apiTodaySchedulesStreamsSelector = createSelector(apiTodaySchedulesOperationSelector, (state) => {
	const items = selectStreamsFromSchedule(state?.current?.schedules);

	return items || [] as Stream[];
});

const apiTodaySchedulesDetectionResultsSelector = createSelector(apiTodaySchedulesOperationSelector, (state) => {
	return state?.current?.detectionResults;
});

const apiSearchSelector = createSelector(apiSelector, (state) => {
	return state?.search;
});

const apiSearchResultSelector = createOperationResultSelector(apiSearchSelector);
const apiSearchErrorSelector = createOperationErrorSelector(apiSearchSelector);

const apiAlarmPresets = () => createOperationResultSelector(createSelector(apiSelector, (state) => { return state?.alarmPresets }));
const apiAlarmPresetsStatusSelector = () => createOperationStatusSelector(createSelector(apiSelector, (state) => { return state?.alarmPresets }));


export const apiSelectors = {
	scheduleRecords: apiScheduleRecordsResultSelector,
	scheduleRecordsStatus: apiScheduleRecordStatusSelector,
	scheduleRecordsItems: apiScheduleRecordsItemsSelector,
	todaySchedulesResults: apiTodaySchedulesOperationSelector,
	todaySchedulesOperationSelector: apiTodaySchedulesOperationSelector,
	scheduleRecordsResult: apiScheduleRecordsResultSelector,
	todaySchedulesStreams: apiTodaySchedulesStreamsSelector,
	todaySchedulesDetectionResults: apiTodaySchedulesDetectionResultsSelector,
	scheduleDetailsResult: apiScheduleDetailsAssetResultSelector,
	searchOperation: apiSearchSelector,
	searchResult: apiSearchResultSelector,
	searchError: apiSearchErrorSelector,
	alarmPresets: apiAlarmPresets,
	alarmPresetsStatus: apiAlarmPresetsStatusSelector,
};
