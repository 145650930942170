import './Loader.css';

export default function Loader (props: {center?: boolean}) {

  return props.center ? <div className="position-fixed top-50 start-50 translate-middle">
      <div className="spinner-grow" style={{ width: "3rem", height: "3rem" }} role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>:
      <div className="spinner-grow" style={{ width: "3rem", height: "3rem" }} role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
  }
