import {createElement, useEffect} from "react";
import './DetailModal.css';
import DetectionResult from "../types/DetectionResult";
import { createS3SignedUrlResultSelector } from '../store/selectors/s3.selectors'
import {readableDuration} from "../util/formatUtils";
import {ISO639_2_ISO639_1} from "../constants";
import { useDispatch, useSelector } from "react-redux";
import { s3Actions } from "../store/actions/s3.actions";
import StatusIcon from "./StatusIcon";
import statusIcons, {SLATE_ICON, ANTHEM_ICON, AD_ICON} from "../types/StatusIconConstants";
interface Props {
  result: DetectionResult;
  language: string | null;
  languageCodes: {
        languageCode: string;
        languageName: string;
  }[];
  onClickStatus: (result: DetectionResult, langTrack?: any) => void
}

export default function DetailResult(props: Props) {
    const dispatch = useDispatch();
    const signedUrlImageBuckeyKey = { bucket: props.result?.languageIdBucket, key: props.result?.frame?.key };

    const signedUrl = useSelector(createS3SignedUrlResultSelector(signedUrlImageBuckeyKey))

    useEffect(() => {
      if (!signedUrl) {
        dispatch(s3Actions.signUrl(signedUrlImageBuckeyKey))
      }
    }, []);

    function getStatusIconWrapStyle (langTrack: { streamId: string; confidence: number; detectedLanguage?: string | boolean; language: string; [p: string]: any }) {
      let style: any = { cursor: 'pointer' }
      if (props.language && props.language === langTrack.language) {
        style['backgroundColor'] = '#ff99007a'
      }
      return style
    }

    let resultColumns;

    if(props.result.slate && props.result.audio.length > 0) {
      // if slate: show slate row
      const icon = statusIcons[SLATE_ICON];
      resultColumns = <td className="indicationRow" colSpan={props.languageCodes.length} onClick={() => { props.onClickStatus(props.result) }}><p>Slate {createElement(icon.icon, { className: icon.color, title: icon.label })}</p></td>
    } else if(props.result.anthem && props.result.audio.length > 0) {
      // if anthem: show anthem row
      const icon = statusIcons[ANTHEM_ICON];
      resultColumns = <td className="indicationRow" colSpan={props.languageCodes.length} onClick={() => { props.onClickStatus(props.result) }}><p>National anthem {createElement(icon.icon, { className: icon.color, title: icon.label })}</p></td>
    } else {
      resultColumns = props.languageCodes.map(lang => {
        const langTrack = props.result.audio.find(track => track.language === lang.languageCode);
        if(!langTrack) {
          return <td><p>-</p></td>
        }

        if (props.result.ad && props.result.audio.length > 0) {
          // if ad: show ad row
          return <td style={getStatusIconWrapStyle(langTrack)}>
          <div className="statusIconWrap" onClick={() => {props.onClickStatus(props.result, langTrack)}}>
          <StatusIcon ad={props.result.ad} langTrack={langTrack} isoCode={ISO639_2_ISO639_1[lang.languageCode]} source="detailView"/>
          </div>
        </td>
        }

        return <td style={getStatusIconWrapStyle(langTrack)}>
          <div className="statusIconWrap" onClick={() => {props.onClickStatus(props.result, langTrack)}}>
            <StatusIcon slate={props.result.slate} langTrack={langTrack} isoCode={ISO639_2_ISO639_1[lang.languageCode]} source="detailView"/>
          </div>
        </td>

      });
    }
    return <tr className="">
      <th scope="row">
        <div className="resultThumbnail" style={{ backgroundImage: `url(${signedUrl})` }} />
      </th>
        <td><p>{readableDuration(Number(props.result.format.startTime * 1000))}</p></td>
        <td><p>{new Date(props.result.segmentTime || props.result.startTime).toISOString()}</p></td>
        {resultColumns}
        <td>{readableDuration(Number(props.result.endTime - props.result.startTime))}</td>
    </tr>
  }
