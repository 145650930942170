import './index.css'

import { useEffect, useState } from 'react'
import MosaicStream from '../../components/MosaicStream'
import FilterBar from '../../components/FilterBar'
import { useDispatch, useSelector } from 'react-redux'
import { apiActions } from '../../store/actions/api.actions'
import { authSelectors } from '../../store/selectors/auth.selectors'
import { apiSelectors } from '../../store/selectors/api.selectors'
import { iotSelectors } from '../../store/selectors/iot.selectors'
import { withErrorBoundary } from 'react-error-boundary'
import ErrorFallback from '../../components/ErrorFallback'

export default withErrorBoundary(Home, {
  FallbackComponent: ErrorFallback,
  onError (error, info) {
    console.log(error)
  },
})

function Home () {

  const dispatch = useDispatch()
  const isAuthenticated = useSelector(authSelectors.isAuthenticated)
  const isConnected = useSelector(iotSelectors.isConnected)
  const todayScheduleStreams = useSelector(apiSelectors.todaySchedulesStreams)
  const todayScheduleDetectionResults = useSelector(apiSelectors.todaySchedulesDetectionResults)
  // @ts-ignore
  const [filters, setFilters] = useState<string[]>(JSON.parse(localStorage.getItem('filters')) || [])
  const [showLive, setShowLive] = useState<boolean>(localStorage.getItem('showLive') == "true")
  const [showVirtual, setShowVirtual] = useState<boolean>(localStorage.getItem('showVirtual') == "true")
  const [showEvent, setShowEvent] = useState<boolean>(localStorage.getItem('showEvent') == "true")
  const [showLinear, setShowLinear] = useState<boolean>(localStorage.getItem('showLinear') == "true")
  const [showAventus, setShowAventus] = useState<boolean>(localStorage.getItem('showAventus') == "true")
  const [showAventusLinear, setShowAventusLinear] = useState<boolean>(localStorage.getItem('showAventusLinear') == "true")
  const [liveStatusByChannel, setLiveStatusByChannel] = useState<{}>((localStorage.getItem('liveStatusByChannel')) || {})
  useEffect(() => {
    if (isAuthenticated && isConnected && !todayScheduleStreams?.length) {
      dispatch(apiActions.getTodaySchedules())
    }
  }, [isAuthenticated, isConnected])

  const curFilter = filters.filter((f) => todayScheduleStreams.map(s => s.streamId).includes(f))

  const handleStatuses = (streamId, liveStaus) => {
    let statuses = liveStatusByChannel;
    statuses[streamId] = liveStaus;
    setLiveStatusByChannel(statuses)
  }

  const streamsFil = todayScheduleStreams.filter((s) => curFilter.length === 0 || curFilter.includes(s.streamId)).sort((a, b) => a.streamId?.localeCompare(b.streamId))
  return (
    <div className="pageWrapper page">
      <div className="container-fluid">
        <div className="mx-3">
          {
            todayScheduleStreams.length > 0 ?
              <div className="row">
                <div className="col-xl-2 col-md-3 col-sm-12">
                  <div className="row">
                    <div className="col-md-10">
                      <div className="form-check form-switch text-left">
                        <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault"
                               defaultChecked={showLive} onClick={() => {
                          localStorage.setItem('showLive', `${!showLive}`);
                          setShowLive(!showLive)
                        }}/>
                        <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Live only</label>
                      </div>
                    </div>
                    <div className="col-md-10">
                      <div className="form-check form-switch text-left">
                        <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault"
                               defaultChecked={showAventus} onClick={() => {
                          localStorage.setItem('showAventus', `${!showAventus}`);
                          setShowAventus(!showAventus)
                        }}/>
                        <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Aventus event
                          channels</label>
                      </div>
                    </div>
                    <div className="col-md-10">
                      <div className="form-check form-switch text-left">
                        <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault"
                               defaultChecked={showLinear} onClick={() => {
                          localStorage.setItem('showLinear', `${!showLinear}`);
                          setShowLinear(!showLinear)
                        }}/>
                        <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Linear channels</label>
                      </div>
                    </div>
                    <div className="col-md-10">
                      <div className="form-check form-switch text-left">
                        <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault"
                               defaultChecked={showAventusLinear} onClick={() => {
                          localStorage.setItem('showAventusLinear', `${!showAventusLinear}`);
                          setShowAventusLinear(!showAventusLinear)
                        }}/>
                        <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Aventus linear channels</label>
                      </div>
                    </div>
                  </div>
                  <FilterBar streams={todayScheduleStreams}
                             filters={filters}
                             onChangeFilter={(newFilters) => setFilters(newFilters)}
                             showLive={showLive}
                             showVirtual={showVirtual}
                             showEvent={showEvent}
                             showLinear={showLinear}
                             showAventus={showAventus}
                             showAventusLinear={showAventusLinear}
                             liveStatusByChannel={liveStatusByChannel}
                             handleStatuses={handleStatuses}
                  />
                </div>
                <div className="col-md-9 col
            -xl-10 col-sm-12">
                  <div className="row">
                    {
                      streamsFil?.map((s, i) => <MosaicStream
                        showLive={showLive}
                        showVirtual={showVirtual}
                        showEvent={showEvent}
                        showLinear={showLinear}
                        showAventus={showAventus}
                        showAventusLinear={showAventusLinear}
                        stream={s}
                        results={todayScheduleDetectionResults?.[s.streamId]!}
                        liveStatusByChannel={liveStatusByChannel}
                      />)
                    }
                  </div>
                </div>
              </div> :
              <div className="alert alert-warning" role="alert">No Scheduled events</div>
          }
        </div>
      </div>
    </div>
  )
}
