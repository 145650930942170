export default interface Stream {
  streamId: string,
  schedules: Schedule[],
  broadcastType?: string
}

export interface Schedule {
  streamId: string,
  assetName: string,
  assetId: string,
  actualStartTime: number,
  actualEndTime: number,
  testChannel: boolean,
  sportType?: string,
  broadcastType?: string,
  audio: {
    languageCode: string,
    languageName: string,
  }[],
  languageCodes?: string,
  config?: {
    alarmConfig?: {
      initialThreshold: number,
      middleThreshold: number,
      endThreshold: number
    },
    muteConfig?: {
      endTime: number
    },
    pauseConfig?: {
      endTime: number
    },
  };
}

export enum SchedulePlayStates {
  Resumed = 'resumed',
  Paused = 'paused',
  Muted = 'muted',
  Unmuted = 'unmuted'
}
