export const languageCodes = [
  { languageCode: 'qis', languageName: 'Ambient Sound' },
  { languageCode: 'eng', languageName: 'English' },
  { languageCode: 'deu', languageName: 'German' },
  { languageCode: 'fra', languageName: 'French' },
  { languageCode: 'swe', languageName: 'Swedish' },
  { languageCode: 'fin', languageName: 'Finnish' },
  { languageCode: 'dan', languageName: 'Danish' },
  { languageCode: 'nor', languageName: 'Norwegian' },
  { languageCode: 'ita', languageName: 'Italian' },
  { languageCode: 'tur', languageName: 'Turkish' },
  { languageCode: 'spa', languageName: 'Spanish' },
  { languageCode: 'por', languageName: 'Portuguese' },
  { languageCode: 'nld', languageName: 'Dutch' },
  { languageCode: 'pol', languageName: 'Polish' },
  { languageCode: 'rus', languageName: 'Russian' },
  { languageCode: 'ron', languageName: 'Romanian' },
  { languageCode: 'ell', languageName: 'Greek' },
  { languageCode: 'srp', languageName: 'Serbian' },
  { languageCode: 'hun', languageName: 'Hungarian' },
  { languageCode: 'ces', languageName: 'Czech' },
  { languageCode: 'bul', languageName: 'Bulgarian' },
  { languageCode: 'zho', languageName: 'Chinese' },
  { languageCode: 'jpn', languageName: 'Japanese' },
  { languageCode: 'kor', languageName: 'Korean' },
  { languageCode: 'tha', languageName: 'Thai' },
  { languageCode: 'vie', languageName: 'Vietnamese' },
  { languageCode: 'ara', languageName: 'Arabic' },
  { languageCode: 'est', languageName: 'Estonian' },
  { languageCode: 'hin', languageName: 'Hindi' },
  { languageCode: 'heb', languageName: 'Hebrew' },
  { languageCode: 'slv', languageName: 'Slovenian' },
  { languageCode: 'lav', languageName: 'Latvian' },
];

export const ISO639_2_ISO639_1: { [key: string] : string } = {
  eng: 'en',
  deu: 'de',
  fra: 'fr',
  swe: 'sv',
  fin: 'fi',
  dan: 'da',
  nor: 'no',
  ita: 'it',
  tur: 'tr',
  spa: 'es',
  por: 'pt',
  nld: 'nl',
  pol: 'pl',
  rus: 'ru',
  ron: 'ro',
  ell: 'el',
  srp: 'sr',
  hun: 'hu',
  ces: 'cs',
  bul: 'bg',
  zho: 'zh',
  jpn: 'ja',
  kor: 'ko',
  tha: 'th',
  vie: 'vi',
  ara: 'ar',
  est: 'et',
  hin: 'hi',
  heb: 'he',
  slv: 'sl',
  lav: 'lv'
};

export const CHANNEL_MAPPING = {
  'eurosport-0': 'EUROSPORT 1 INTERNATIONAL',
  'eurosport-1': 'EUROSPORT 1 GERMANY SD',
  'eurosport-101': 'EUROSPORT 1 GERMANY HD',
  'eurosport-3': 'EUROSPORT 1 FRANCE',
  'eurosport-35': 'EUROSPORT 1 FLANDERS',
  'eurosport-36': 'EUROSPORT 1 WALLONIA',
  'eurosport-5': 'EUROSPORT 1 NETHERLANDS',
  'eurosport-14': 'EUROSPORT 1 POLAND',
  'eurosport-15': 'EUROSPORT 1 RUSSIA',
  'eurosport-20': 'EUROSPORT 1 CAAC',
  'eurosport-6': 'EUROSPORT 1 SPAIN',
  'eurosport-4': 'EUROSPORT 1 ITALY (SKY)',
  'eurosport-104': 'EUROSPORT 1 ITALY (NON SKY)',
  'eurosport-16': 'EUROSPORT 1 ROMANIA',
  'eurosport-19': 'EUROSPORT 1 BULGARIA',
  'eurosport-18': 'EUROSPORT 1 CZECH',
  'eurosport-17': 'EUROSPORT 1 HUNGARY',
  'eurosport-1600': 'EUROSPORT 1 NON OLYMPIC',
  'eurosport-9': 'EUROSPORT 1 TURKIYE',
  'eurosport-37': 'EUROSPORT 1 NEE',
  'eurosport-8': 'EUROSPORT 1 PORTUGAL',
  'eurosport-200': 'EUROSPORT 2 INTERNATIONAL',
  'eurosport-2201': 'EUROSPORT 2 GERMANY',
  'eurosport-270': 'EUROSPORT 2 NW',
  'eurosport-203': 'EUROSPORT 2 FRANCE',
  'eurosport-235': 'EUROSPORT 2 FLANDERS',
  'eurosport-236': 'EUROSPORT 2 WALLONIA',
  'eurosport-205': 'EUROSPORT 2 NL',
  'eurosport-2214': 'EUROSPORT 2 POLAND',
  'eurosport-265': 'EUROSPORT 2 RUSSIA',
  'eurosport-220': 'EUROSPORT 2 CAAC',
  'eurosport-206': 'EUROSPORT 2 SPAIN',
  'eurosport-288': 'EUROSPORT 2 PORTUGAL',
  'eurosport-204': 'EUROSPORT 2 ITALY (SKY)',
  'eurosport-2204': 'EUROSPORT 2 ITALY (NON SKY)',
  'eurosport-216': 'EUROSPORT 2 ROMANIA',
  'eurosport-2251': 'EUROSPORT 2 CEEMCA ',
  'eurosport-209': 'EUROSPORT 2 TURKIYE',
  'eurosport-219': 'EUROSPORT 2 BULGARIA',
  'eurosport-237': 'EUROSPORT 2 NEE',
  'eurosport-300': 'EUROSPORT WORLD',
  'eurosport-2': 'EUROSPORT 1 BRITISH',
  'eurosport-202': 'EUROSPORT 2 BRITISH',
  'eurosport-11': 'EUROSPORT 1 DENMARK',
  'eurosport-2211': 'EUROSPORT 2 DENMARK',
  'eurosport-13': 'EUROSPORT 1 NORWAY',
  'eurosport-2213': 'EUROSPORT 2 NORWAY',
  'eurosport-7': 'EUROSPORT 1 SWEDEN',
  'eurosport-2207': 'EUROSPORT 2 SWEDEN',
  'eurosport-112': 'EUROSPORT 1 FINLAND',
  'eurosport-12': 'EUROSPORT 1 FINLAND',
  'esp-0': 'EUROSPORT 1 INTERNATIONAL (AV)',
  'esp-1': 'EUROSPORT 1 GERMANY SD (AV)',
  'esp-101': 'EUROSPORT 1 GERMANY HD (AV)',
  'esp-3': 'EUROSPORT 1 FRANCE (AV)',
  'esp-35': 'EUROSPORT 1 FLANDERS (AV)',
  'esp-36': 'EUROSPORT 1 WALLONIA (AV)',
  'esp-5': 'EUROSPORT 1 NETHERLANDS (AV)',
  'esp-14': 'EUROSPORT 1 POLAND (AV)',
  'esp-15': 'EUROSPORT 1 RUSSIA (AV)',
  'esp-20': 'EUROSPORT 1 CAAC (AV)',
  'esp-6': 'EUROSPORT 1 SPAIN (AV)',
  'esp-4': 'EUROSPORT 1 ITALY (SKY) (AV)',
  'esp-104': 'EUROSPORT 1 ITALY (NON SKY) (AV)',
  'esp-16': 'EUROSPORT 1 ROMANIA (AV)',
  'esp-19': 'EUROSPORT 1 BULGARIA (AV)',
  'esp-18': 'EUROSPORT 1 CZECH (AV)',
  'esp-17': 'EUROSPORT 1 HUNGARY (AV)',
  'esp-1600': 'EUROSPORT 1 NON OLYMPIC (AV)',
  'esp-9': 'EUROSPORT 1 TURKIYE (AV)',
  'esp-37': 'EUROSPORT 1 NEE (AV)',
  'esp-8': 'EUROSPORT 1 PORTUGAL (AV)',
  'esp-200': 'EUROSPORT 2 INTERNATIONAL (AV)',
  'esp-2201': 'EUROSPORT 2 GERMANY (AV)',
  'esp-270': 'EUROSPORT 2 NW (AV)',
  'esp-203': 'EUROSPORT 2 FRANCE (AV)',
  'esp-235': 'EUROSPORT 2 FLANDERS (AV)',
  'esp-236': 'EUROSPORT 2 WALLONIA (AV)',
  'esp-205': 'EUROSPORT 2 NL (AV)',
  'esp-2214': 'EUROSPORT 2 POLAND (AV)',
  'esp-265': 'EUROSPORT 2 RUSSIA (AV)',
  'esp-220': 'EUROSPORT 2 CAAC (AV)',
  'esp-206': 'EUROSPORT 2 SPAIN (AV)',
  'esp-288': 'EUROSPORT 2 PORTUGAL (AV)',
  'esp-204': 'EUROSPORT 2 ITALY (SKY) (AV)',
  'esp-2204': 'EUROSPORT 2 ITALY (NON SKY) (AV)',
  'esp-216': 'EUROSPORT 2 ROMANIA (AV)',
  'esp-2251': 'EUROSPORT 2 CEEMCA (AV)',
  'esp-209': 'EUROSPORT 2 TURKIYE (AV)',
  'esp-219': 'EUROSPORT 2 BULGARIA (AV)',
  'esp-237': 'EUROSPORT 2 NEE (AV)',
  'esp-300': 'EUROSPORT WORLD (AV)',
  'esp-2': 'EUROSPORT 1 BRITISH (AV)',
  'esp-202': 'EUROSPORT 2 BRITISH (AV)',
  'esp-11': 'EUROSPORT 1 DENMARK (AV)',
  'esp-2211': 'EUROSPORT 2 DENMARK (AV)',
  'esp-13': 'EUROSPORT 1 NORWAY (AV)',
  'esp-2213': 'EUROSPORT 2 NORWAY (AV)',
  'esp-7': 'EUROSPORT 1 SWEDEN (AV)',
  'esp-2207': 'EUROSPORT 2 SWEDEN (AV)',
  'esp-112': 'EUROSPORT 1 FINLAND (AV)',
  'esp-12': 'EUROSPORT 1 FINLAND (AV)',
  'eurosport-501': 'TNT Sport 1',
  'eurosport-502': 'TNT Sport 2',
  'eurosport-503': 'TNT Sport 3',
  'eurosport-504': 'TNT Sport 4',
  'eurosport-505': 'TNT Sport 5',
  'eurosport-506': 'TNT Sport 6',
  'eurosport-507': 'TNT Sport 7',
  'eurosport-508': 'TNT Sport 8',
  'eurosport-509': 'TNT Sport 9',
  'eurosport-510': 'TNT Sport 10',
  'eurosport-599': 'TNT Sport Ultimate',
};

export const PERCEPTUAL_DISTANCE = 0.10;
export const PIXEL_DISTANCE = 0.10;
