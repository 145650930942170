import { Hub } from "aws-amplify";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { authActions } from "../store/actions/auth.actions";
import { iotActions } from "../store/actions/iot.actions";

export const useAuth = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        //listen for an authentication event
        Hub.listen('auth', (authevent) => {
            const { payload } = authevent;
            if (payload.event === 'signIn') {
                console.log(`A new signIn event has happened: ${payload.data.username} has ${payload.event}`);
                dispatch(authActions.loadAuth());
            } else if(payload.event === 'signOut') {
                dispatch(authActions.clearAuth());
                dispatch(iotActions.disconnect());
            }
        });
        dispatch(authActions.loadAuth());
    }, []);
}