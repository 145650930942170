import {Schedule} from "../types/Stream";
import AlarmPreset from "../types/AlarmPreset";
import ReactTooltip from "react-tooltip";

export default function SportTypeBadge(props: { scheduleDetails: Schedule, presetLangSpec?: AlarmPreset, presetAudioSpec?: AlarmPreset }) {

    const langSpecLabel = props.presetLangSpec?.sk.split('#')[1];

    return <h3><span className={`badge bg-secondary position-absolute top-0 start-0 ${langSpecLabel}`}
                     data-tip="">{props.scheduleDetails.sportType}</span>
        <ReactTooltip place="bottom" effect="solid">
            <p>Alarm Commentary Issues after:</p>
            <p>{props.presetLangSpec?.value.CommentaryIssues?.DatapointsToAlarm! * props.presetLangSpec?.value.CommentaryIssues?.Threshold!} false
                detections</p>
            <p>within {props.presetLangSpec?.value.CommentaryIssues?.Period! * props.presetLangSpec?.value.CommentaryIssues?.EvaluationPeriods!} seconds</p>
            <br/>
            <p>Alarm Signal Issues after:</p>
            <p>{props.presetLangSpec?.value.SignalIssues?.DatapointsToAlarm! * props.presetLangSpec?.value.SignalIssues?.Threshold!} false
                detections</p>
            <p>within {props.presetLangSpec?.value.SignalIssues?.Period! * props.presetLangSpec?.value.SignalIssues?.EvaluationPeriods!} seconds</p>
            <br/>
            <p>Alarm Audio Issues after:</p>
            <p>{props.presetAudioSpec?.value.AudioIssues?.DatapointsToAlarm! * props.presetAudioSpec?.value.AudioIssues?.Threshold!} false
                detections</p>
            <p>within {props.presetAudioSpec?.value.AudioIssues?.Period! * props.presetAudioSpec?.value.AudioIssues?.EvaluationPeriods!} seconds</p>
            <br/>
        </ReactTooltip></h3>;
}
