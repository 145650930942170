import React, {useEffect, useMemo, useRef} from "react";
import './DetailModal.css';
import DetectionResult from "../types/DetectionResult";
import { createS3SignedUrlResultSelector} from '../store/selectors/s3.selectors'
import {readableDuration} from "../util/formatUtils";
import {PERCEPTUAL_DISTANCE, PIXEL_DISTANCE} from "../constants";
import { useDispatch, useSelector } from "react-redux";
import { s3Actions } from "../store/actions/s3.actions";
import {FaTimes} from "react-icons/fa";

interface Props {
  segment: { r: DetectionResult, track?: {[key: string]: any}} | null,
  show: boolean,
  onClose: Function,
}

const CommentaryTableColumns = [
  'Timecode',
  'SegmentTime',
  'Duration',
  'Audio Codec',
  'Dolby',
  'Audio Channels',
  'Expected',
  'Detected',
  'Confidence',
  'Signal',
  'Slate?',
  'Anthem?',
  'Ad?',
  'Commentary?',
  'Poor Audio Quality?'
];

const createBucketKey = (bucket?: string, key?: string) => ({bucket: bucket || '', key: key || ''})


export default function DetailModal(props: Props) {
  const dispatch = useDispatch();

  const videoUrlBucketKey = createBucketKey(props.segment?.r?.languageIdBucket, props.segment?.r?.video?.key);
  const ambientSpectrumUrlBucketKey = createBucketKey(props.segment?.track?.bucket, props.segment?.track?.ambientSpectrum);
  const spectrumUrlBucketKey = createBucketKey(props.segment?.track?.bucket, props.segment?.track?.spectrum);

  // use language audio if language track is provided, if not use ambient audio instead
  const audioKey = (props.segment?.track ? props.segment?.track?.key : props.segment?.r?.ambient?.key);
  const audioUrlBucketKey = createBucketKey(props.segment?.r?.languageIdBucket, audioKey);

  const signedVideoUrl = useSelector(createS3SignedUrlResultSelector(videoUrlBucketKey));
  const signedAmbientSpectrumURL = useSelector(createS3SignedUrlResultSelector(ambientSpectrumUrlBucketKey));
  const signedSpectrumUrl = useSelector(createS3SignedUrlResultSelector(spectrumUrlBucketKey));
  const signedAudioUrl = useSelector(createS3SignedUrlResultSelector(audioUrlBucketKey));

  useEffect(() => {
    if (props.segment) {
      dispatch(s3Actions.signUrl(videoUrlBucketKey!));
    }
    if (props.segment?.track?.ambientSpectrum) {
      dispatch(s3Actions.signUrl(ambientSpectrumUrlBucketKey));
    }
    if (props.segment?.track?.bucket) {
      dispatch(s3Actions.signUrl(spectrumUrlBucketKey))
    }
    if (props.segment?.r.languageIdBucket) {
      dispatch(s3Actions.signUrl(audioUrlBucketKey))
      
    }
    
  }, [props.segment]);

  const audio = useMemo(() => new Audio(signedAudioUrl), [signedAudioUrl]);

  if(!props.show || !props.segment) return null;

  const segment = props.segment;

  function closeModal() {
    audio.pause();
    props.onClose();
  }

  const signalDetected = String(segment.track?.signal.perceptualDistance >= PERCEPTUAL_DISTANCE
    || segment.track?.signal.pixelDistance >= PIXEL_DISTANCE);
  const isLinear = segment.track?.streamId.split("-")[1].length < 5 ? true : false;
  let commentaryDetected = '--';
  if(!segment.track?.ambientSound && isLinear) {
    const detectedLang = segment.track?.detectedLanguage;
    commentaryDetected = (detectedLang === undefined || detectedLang === false) ? 'false' : 'true';
  }
  else {
    commentaryDetected = segment.track?.ambientSound === undefined
        ? '--'
        : String(segment.track?.ambientSound.perceptualDistance >= PERCEPTUAL_DISTANCE
        || segment.track?.ambientSound.pixelDistance >= PIXEL_DISTANCE);
  }

  const confidence = segment.track?.confidence === undefined
    ? '--'
    : `${Math.round(segment.track?.confidence * 100)}%`;
  const perceptualDistance = (segment.track?.ambientSound && segment.track?.ambientSound.perceptualDistance ? segment.track?.ambientSound.perceptualDistance : "--");
  const pixelDistance = (segment.track?.ambientSound && segment.track?.ambientSound.pixelDistance ? segment.track?.ambientSound.pixelDistance : "--");

  const audioQualityObj = {
    volumeLeft: segment.track?.volumeLeft,
    volumeRight: segment.track?.volumeRight,
    dynamicRange: segment.track?.dynamicRange,
    noiseFloorDb: segment.track?.noiseFloorDb,
    noiseFloorCount: segment.track?.noiseFloorCount,
  }

  // This is for debugging purposes only:
  console.log(`Slate detection results: \n${JSON.stringify(segment.r?.slateDetections, null, 2)}`);
  console.log(`Anthem Detection results: \n${JSON.stringify(segment.r?.anthemDetections, null, 2)}`);
  console.log(`Ad Detection results: \n${JSON.stringify(segment.r.logoDetections, null, 2)}`)
  console.log(`Commentary Detection: \n${JSON.stringify({ hammingDistance: perceptualDistance, pixelDistance }, null, 2)}`);
  console.log(`Audio Quality Detection: \n${JSON.stringify(audioQualityObj, null, 2)}`);
  console.log('Bucket: ' + segment.r?.bucket + ' Key: ' + segment.r?.key);

  return (
    <div className="modalBackdrop" onClick={() => closeModal()}>
      <div className="detailModal" onClick={(event) => event.stopPropagation()}
           aria-labelledby="simple-modal-title"
           aria-describedby="simple-modal-description">
        <FaTimes className="closeButton" onClick={() => closeModal()}/>
        <div className="modalContent">
          <div className="multiMediaContainer">
            <video className="thumbnail" controls onPlay={() => audio.play()} onPause={() => audio.pause()} onSeeking={(e) => { // @ts-ignore
              audio.currentTime = e.target.currentTime}} src={signedVideoUrl}>
            </video>
            <div className="spectrumImagesContainer" >
              { segment.track?.ambientSpectrum &&
                <div>
                  <p><b>Ambient sound</b></p>
                  <img alt="ambient spectrum" className="spectrumImage" src={signedAmbientSpectrumURL}/>
                </div>
              }
              { segment.track &&
                <div>
                  <p><b>Commentary sound</b></p>
                  <img alt="language spectrum" className="spectrumImage" src={signedSpectrumUrl}/>
                </div>
              }
            </div>
          </div>
          <div className="detailsSection">
            <div className="detailsSectionColumn">
              {CommentaryTableColumns.map(col => <p>{col}</p>)}
            </div>
            <div className="detailsSectionColumn">
              <p>{readableDuration(Number(segment.r.format.startTime * 1000))}</p>
              <p>{new Date(segment.r.segmentTime || segment.r.startTime).toISOString().replace('T', '\nT')}</p>
              <p>{Math.round(segment.r.format.duration)}</p>
              <p>{segment.track?.codecName || "--"}</p>
              <p>{segment.track?.commercialName || "--"}</p>
              <p>{segment.track?.channels || "--"}</p>
              <p>{segment.track?.language}</p>
              <p>{segment.track?.detectedLanguage || "--"}</p>
              <p>{confidence}</p>
              <p>{signalDetected}</p>
              <p>{segment.r?.slate?.toString() || "--"}</p>
              <p>{segment.r?.anthem?.toString() || "--"}</p>
              <p>{segment.r.ad.toString()}</p>
              <p>{commentaryDetected}</p>
              <p>{segment.track?.audioIssue.toString()}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}