import {combineReducers} from 'redux';
import {StateType} from 'typesafe-actions';

import {  CLEAR_AUTH, LOAD_AUTH, LOAD_AUTH_ERROR, LOAD_AUTH_SUCCESS} from '../constants/auth.constants';
import {DeepReadonlyObject} from '../types/reducers/DeepReadonly';
import {OperationalState} from '../types/reducers/OperationStatus';
import { Auth } from 'aws-amplify';
import {getStateError, getStateInitial, getStateInProgress, getStateSuccess} from '../utils/reducers/state.util';
import { NullableState } from '../types/reducers/NullableState';
import { UnwrapPromise } from '../../types/UnwrapPromise';
import { authActions } from '../actions/auth.actions';

type AuthCurrentCredentials = UnwrapPromise<ReturnType<typeof Auth.currentCredentials>>;

export type AuthState = {
	credentials: AuthCurrentCredentials;
};

type AuthStateOperational = OperationalState<AuthState>;
type AuthStateNullable = NullableState<AuthStateOperational>;
type AuthStateReadonly = DeepReadonlyObject<AuthStateNullable>;

export const initialAuthState: AuthStateNullable = {
	credentials: getStateInitial()
};

export const authReducers = combineReducers<AuthStateReadonly, authActions>({
	credentials: (state = initialAuthState.credentials, action) => {
        switch (action.type) {
            case LOAD_AUTH: {
                return getStateInProgress();
            }
            case LOAD_AUTH_SUCCESS: {
                const {response} = action.payload;
    
                return getStateSuccess(response);
            }
            case LOAD_AUTH_ERROR: {
                const {error} = action.payload;
    
                return getStateError(error);
            }
            case CLEAR_AUTH: {
                return initialAuthState.credentials;
            }
            default:
                return state;
            }
	}
});

export type authReducers = StateType<typeof authActions>;