import {all, call, put, select, takeEvery} from 'redux-saga/effects';
import SolutionManifest from "../../services/SolutionManifest";
import AWS from "aws-sdk"
import { s3Actions } from '../actions/s3.actions';
import { S3_SIGN_URL } from '../constants/s3.constants';
import { authSelectors } from '../selectors/auth.selectors';
import { Auth } from 'aws-amplify';


export const s3Sagas = () => {
  
  function* signUrlSaga(action: ReturnType<typeof s3Actions['signUrl']>) {
    const request = action.payload.request;
    try {
      
      const { bucket, key } = request;
      yield call(
        Auth.currentAuthenticatedUser.bind(Auth)
      );

      //if we're here then we are good to fetch creds
      const credentials = yield call(
        Auth.currentCredentials.bind(Auth)
      );
      // console.log(credentials);
      const s3 = new AWS.S3({
        credentials: credentials,
        region: SolutionManifest.Region,
        apiVersion: '2006-03-01',
        computeChecksums: true,
        signatureVersion: 'v4',
        s3DisableBodySigning: false,
      });

      const signedUrl = s3.getSignedUrl(
        'getObject',
        {
          Bucket: bucket,
          Key: key,
          Expires: 60 * 60 * 2,
        }
      );

      yield put(s3Actions.signUrlSuccess(signedUrl, request));
    }
    catch (err) {
      console.error(`An error occurred while generating the signed url`, err); 
      yield put(s3Actions.signUrlError(err, request));
    }
  }

  return {
    watcher: function* () {
      yield all([takeEvery(S3_SIGN_URL, signUrlSaga)]);
    }
  }
}