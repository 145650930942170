
import './index.css';

import {useEffect, useState} from "react";
import TextInput from "../../components/TextInput";
import {apiActions} from "../../store/actions/api.actions";
import {useDispatch, useSelector} from "react-redux";
import {apiSelectors} from "../../store/selectors/api.selectors";
import {authSelectors} from "../../store/selectors/auth.selectors";
import {iotSelectors} from "../../store/selectors/iot.selectors";
import Loader from "../../components/Loader";

export default function Settings() {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(authSelectors.isAuthenticated);
  const isConnected = useSelector(iotSelectors.isConnected);
  const isAuthenticatedAndConnected = isAuthenticated && isConnected;
  const alarmPresets = useSelector(apiSelectors.alarmPresets());
  const isLoading = useSelector(apiSelectors.alarmPresetsStatus())?.isLoading;

  useEffect(() => {
    if(isAuthenticatedAndConnected) {
      dispatch(apiActions.getAlarmPresets());
    }
  }, [isAuthenticatedAndConnected]);

  const renderedSports = () => {
    return alarmPresets?.sports.sort((a, b) => +(a.sk > b.sk)).map((s) => {
      const langSpecLabel = s.langSpec.split('#')[1];
      const audioSpecLabel = s.audioSpec.split('#')[1];
      return (
            <tr>
              <td>
              {s.sk}
              </td>
              <td><span className={`badge  ${langSpecLabel}`}>{langSpecLabel.charAt(0).toUpperCase() + langSpecLabel.slice(1)}</span></td>
              <td><span className={`badge ${audioSpecLabel}`}>{audioSpecLabel.charAt(0).toUpperCase() + audioSpecLabel.slice(1)}</span></td>
            </tr>
      );
    })
  }

  const renderedPresets = () => {
    return alarmPresets?.presets.sort((a, b) => +(a.sk > b.sk)).map((s) => {
      const label = s.sk.split('#')[0].replace("Spec", "").toUpperCase();
      const langSpecLabel = s.sk.split('#')[1];
      const {CommentaryIssues, SignalIssues, AudioIssues} = s?.value;
      return (
          <><div className="card" key={JSON.stringify(s)}>
            <div className="card-header text-left">
              <span className={`badge ${langSpecLabel}`}>{langSpecLabel.charAt(0).toUpperCase() + langSpecLabel.slice(1)}</span> {label}
            </div>
            <div className="card-body alarmPreset">
              {CommentaryIssues && <>
                  <p>Alarm Commentary Issues after:</p>
                <p>{CommentaryIssues.DatapointsToAlarm * CommentaryIssues.Threshold} false detections</p>
                <p>within {CommentaryIssues.Period * CommentaryIssues.EvaluationPeriods} seconds</p>
              </>}
              {SignalIssues && <><hr/>
                  <p>Alarm Signal Issues after:</p>
                <p>{SignalIssues.DatapointsToAlarm * SignalIssues.Threshold} false detections</p>
                <p>within {SignalIssues.Period * SignalIssues.EvaluationPeriods} seconds</p>
              </>}

              {AudioIssues && <>
                <p>Alarm Audio Issues after:</p>
                <p>{AudioIssues.DatapointsToAlarm * AudioIssues.Threshold} false detections</p>
                <p>within {AudioIssues.Period * AudioIssues.EvaluationPeriods} seconds</p>
              </>}
                </div>
          </div>
          <br/></>
      );
    })
  }

  function renderSportsTable() {
    return <table className="table table-striped">
      <thead>
      <tr>
        <th scope="col">Sport</th>
        <th scope="col">Lang</th>
        <th scope="col">Audio</th>
      </tr>
      </thead>
      <tbody>
      {renderedSports()}
      </tbody>
    </table>;
  }

  return (
    <div className="container">
      <div className="row">
        <h3 className="display-6">Sport alarm presets (read-only)</h3>
        <div className="col-md-6">
          {renderSportsTable()}
        </div>
        <div className="col-md-6">
          {renderedPresets()}
        </div>
      </div>
        {isLoading && <Loader center={true}/>}
    </div>
  );
}
