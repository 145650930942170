import { Link } from 'react-router-dom'
import React from 'react'
import StyledButton from "../../components/StyledButton";

export default function Error404 () {

  return (
    <div className="detailsWrapper">
      <h1>Error 404</h1>
      <h2>It seems like we couldn't find the page you were looking for</h2>
      <StyledButton>
        <Link to="/">Return to Home Page</Link>
      </StyledButton>
    </div>)
}
