import { Action, ActionType,  PayloadAction } from 'typesafe-actions';
import {createAction} from '../utils/actions/action-creators.util';
import { IOT_CONNECT, IOT_CONNECT_ERROR, IOT_CONNECT_SUCCESS, IOT_DISCONNECT, IOT_DISCONNECT_ERROR, IOT_DISCONNECT_SUCCESS, IOT_REGISTER_LISTENER, IOT_REGISTER_LISTENER_ERROR, IOT_REGISTER_LISTENER_SUCCESS } from '../constants/iot.constants';

export const iotActions = {
	/**
	 * IOT_CONNECT
	 */
    connect: (): Action<typeof IOT_CONNECT> => createAction(IOT_CONNECT),
	connectError: (error: Error): PayloadAction<typeof IOT_CONNECT_ERROR, {error: typeof error}> =>
		createAction(IOT_CONNECT_ERROR, {error}),
    connectSuccess: (): Action<typeof IOT_CONNECT_SUCCESS> =>
		createAction(IOT_CONNECT_SUCCESS),

    /**
	 * IOT_DISCONNECT
	 */
    disconnect: (): Action<typeof IOT_DISCONNECT> => createAction(IOT_DISCONNECT),
    disconnectError: (error: Error): PayloadAction<typeof IOT_DISCONNECT_ERROR, {error: typeof error}> =>
        createAction(IOT_DISCONNECT_ERROR, {error}),
    disconnectSuccess: (): Action<typeof IOT_DISCONNECT_SUCCESS> =>
        createAction(IOT_DISCONNECT_SUCCESS),
         
    /**
	 * IOT_REGISTER_LISTENER
	 */
    registerListener: (listener: (data: any, stateRef: any) => void, stateRef?: any, type: 'subscribe' | 'unsubscribe' = 'subscribe'): PayloadAction<typeof IOT_REGISTER_LISTENER, { listener: typeof listener, stateRef: typeof stateRef, type: typeof type }> => createAction(IOT_REGISTER_LISTENER, { listener, stateRef, type}),
    registerListenerError: (error: Error): PayloadAction<typeof IOT_REGISTER_LISTENER_ERROR, {error: typeof error}> =>
        createAction(IOT_REGISTER_LISTENER_ERROR, {error}),
    registerListenerSuccess: (): Action<typeof IOT_REGISTER_LISTENER_SUCCESS> =>
        createAction(IOT_REGISTER_LISTENER_SUCCESS)
};

export type iotActions = ActionType<typeof iotActions>;