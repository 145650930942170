import React, { ReactNode } from "react";
import {FaBell, FaBellSlash, FaEye, FaEyeSlash} from "react-icons/fa";
import { usePrevious } from "../hooks/use-previous-hook";
import { Schedule, SchedulePlayStates } from "../types/Stream";
import { getPlayStateFromSchedule } from "../util/scheduleHelper";
import './SchedulePlayState.css';

interface SchedulePlayStateProps {
  schedule?: Schedule;
}

interface TooltipProps {
  children: ReactNode;
  title: string;
}

export function Tooltip({ title, children }: TooltipProps) {
  return (
    <div className="tooltipContainer">
      {children}
      <div className="tooltip show">
      <p><b>{title}</b><br/></p>
      </div>
    </div>
  );
}

export default function SchedulePlayState({ schedule }: SchedulePlayStateProps) {
  const prevSchedule = usePrevious(schedule);

  
  const states = getPlayStateFromSchedule(schedule || prevSchedule);
  
  const iconFontSize = 20;

  return <div className="schedulePlayStateContainer">
    
    {states[SchedulePlayStates.Muted] && <Tooltip title="PagerDuty is OFF"><FaBellSlash size={iconFontSize} /></Tooltip>}
    {states[SchedulePlayStates.Unmuted] && <Tooltip title="PagerDuty is ON"><FaBell size={iconFontSize} /></Tooltip>}

    {states[SchedulePlayStates.Paused] && <Tooltip title="AI Detection is OFF"><FaEyeSlash size={iconFontSize} /></Tooltip>}
    {states[SchedulePlayStates.Resumed] && <Tooltip title="AI Detection is ON"><FaEye size={iconFontSize} /></Tooltip>}

  </div>
}
