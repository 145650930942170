import { all, fork } from '@redux-saga/core/effects';
import { apiSagas } from './api.sagas';
import { authSagas } from './auth.sagas';
import { iotSagas } from './iot.sagas';
import { s3Sagas } from './s3.sagas';

export const rootSagas = function* () {
	yield all([
		fork(
			apiSagas().watcher
		),
		fork(
			authSagas().watcher
		),
		fork(
			iotSagas().watcher
		),
		fork(
			s3Sagas().watcher
		),
	]);
};