import { OperationState } from '../store/types/reducers/OperationStatus';
import "./ImageLoader.css";
import { HTMLAttributes } from 'react';
import { usePrevious } from '../hooks/use-previous-hook';

export type ImageLoaderProps = HTMLAttributes<HTMLDivElement> & {
    signedImageUrlOperation: OperationState<string>;
}; 

export default function ImageLoader({ signedImageUrlOperation: operation, ...rest }: ImageLoaderProps) {

    const current = operation?.current;
    const prevCurrent = usePrevious(current);

    const currentOrPrevUrl = current || prevCurrent;

    const hasLoaded = !!currentOrPrevUrl && operation?.status?.isLoading === false;

    return <div {...rest} className={`imageLoaderImage ${hasLoaded ? 'imageLoaderImage-show' : 'imageLoaderImage-hide'} ${rest.className}`} style={{ ...rest.style, ...(currentOrPrevUrl?.length ? { backgroundImage: `url(${currentOrPrevUrl})` } : {})}}>
        {rest?.children}
    </div>
} 