import { useLocation } from 'react-router-dom';

import './index.css';
import { languageCodes as langCodes} from "../../constants";
import {createElement, createRef, useEffect, useState} from "react";
import DetectionResult from "../../types/DetectionResult";
import DetailModal from "../../components/DetailModal";
import {useDispatch, useSelector} from "react-redux";
import DetailResult from "../../components/DetailResult";
import { apiActions } from '../../store/actions/api.actions';
import { apiSelectors } from '../../store/selectors/api.selectors';
import { authSelectors } from '../../store/selectors/auth.selectors';
import { iotSelectors } from '../../store/selectors/iot.selectors';
import DetailsHeader from '../../components/DetailsHeader'
import { usePrevious } from '../../hooks/use-previous-hook';
import Loader from '../../components/Loader'
import { withErrorBoundary } from 'react-error-boundary'
import ErrorFallback from '../../components/ErrorFallback'
import statusIcons from '../../types/StatusIconConstants';

export default withErrorBoundary(Details, {
  FallbackComponent: ErrorFallback,
  onError(error, info) {
    console.log(error);
  },
})

function Details() {
  const assetId = new URLSearchParams(useLocation().search).get("assetId") || '';
  const startTimeParam = new URLSearchParams(useLocation().search).get("startTime");
  const endTimeParam = new URLSearchParams(useLocation().search).get("endTime");
  const startTime = startTimeParam !== null ? parseInt(startTimeParam) : 0;
  const endTime = endTimeParam !== null ? parseInt(endTimeParam) : 0;
  const language = new URLSearchParams(useLocation().search).get("language");
  const isAuthenticated = useSelector(authSelectors.isAuthenticated)
  const isConnected = useSelector(iotSelectors.isConnected)
  const dispatch = useDispatch();
  const [detailSegment, setDetailSegment] = useState<{ r: DetectionResult, track?: {[key: string]: any}} | null>(null)
  const detailsScheduleRecordsSearchKey = { assetId, type: 'details', ...{startTime, endTime, maxResults: 25, } };
  const currResults = useSelector(apiSelectors.scheduleRecords(JSON.stringify(detailsScheduleRecordsSearchKey)));
  const resultsStatus = useSelector(apiSelectors.scheduleRecordsStatus(JSON.stringify(detailsScheduleRecordsSearchKey)));
  const currResultsItems = currResults?.records;
  const prevResults = usePrevious(currResultsItems);

  const isLoading = useSelector(apiSelectors.scheduleRecordsStatus(JSON.stringify(detailsScheduleRecordsSearchKey)))?.isLoading;
  const results = currResultsItems || prevResults;
  const isAuthenticatedAndConnected = isAuthenticated && isConnected;

  const scheduleRecordsSearch: Parameters<typeof apiActions.getScheduleRecords>[0]  = { assetId, type: 'details', options: {startTime, endTime, maxResults: 25}};

  useEffect(() => {
    if(isAuthenticatedAndConnected) {
      dispatch(apiActions.getScheduleRecords(scheduleRecordsSearch));
    }
  }, [isAuthenticatedAndConnected]);

  const [offset, setOffset] = useState(0);

  useEffect(() => {
    if((offset > 0 && (document.documentElement.scrollHeight - window.innerHeight - 100) <= offset) && (currResults?.nextToken !== undefined) && (!resultsStatus?.isLoading)) {
      dispatch(apiActions.getScheduleRecords({ ...scheduleRecordsSearch, options: { ...scheduleRecordsSearch.options, nextToken: currResults.nextToken } }));
    }
  }, [offset]);

  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);
    // clean up code
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });

    return () => { // return a cleanup function to unregister our function since its gonna run multiple times
      window.removeEventListener('scroll', onScroll);
      window.removeEventListener("resize", (e) => {});
    };
  }, []);

  // @ts-ignore
  const resultLanguages = [ ...(results?.length ? new Set(...results.map(results => results.audio.map(t => t.language))) : [])];
  const languageCodes = langCodes.filter(x => resultLanguages.includes(x.languageCode));
  const tableColumns = [
    '#',
    'Timecode',
    'SegmentTime',
    ...languageCodes.map(x => x.languageCode.toUpperCase()),
    'Analyze time',
  ];

  const onClickStatusHandler = (result, langTrack?) => setDetailSegment({ r: result, track: langTrack });

  const renderedResultRows = () => {
    return results?.map((r) => <DetailResult key={`${r.startTime}${r.endTime}`} result={r} language={language} languageCodes={languageCodes} onClickStatus={onClickStatusHandler} />);
  };

  const now = Date.now();
  return (
    <div className="pageWrapper page">
      <div className="container-fluid">
        <div className="mx-3">
          <DetailsHeader assetId={assetId} startTime={startTime} endTime={endTime} now={now} detectionResults={results || []}
                         isLoading={isLoading || false}
                         playoutId={results && results[0]?.playoutId}
                         streamId={results && results[0]?.streamId}/>
          <details className="iconLegend">
            <summary>
              <span><b>Icon legend</b></span>
            </summary>
            <table className="table table-hover text-left">
              <thead>
              <tr>
                <th className="iconCell">Icon</th>
                <th>Definition</th>
              </tr>
              </thead>
              <tbody>
                {Object.entries(statusIcons).map(([k, i]) => {
                  return (
                    <tr key={k}>
                      <td className="iconCell">{createElement(i.icon, { className: (i.label === 'Ad detected') ? `${i.color} ad-icon` : `${i.color}` })}</td>
                      <td>{i.label}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </details>
          <div className="resultsTable">
            <table className="table table-hover align-middle">
              <thead className="resultsTableHeader">
                <tr>
                  {tableColumns.map(col => <th scope="col">{col}</th>
                  )}
                </tr>
              </thead>
              <tbody>
                {renderedResultRows()}
                { currResults?.nextToken === undefined &&
                  <td className="slateRow" colSpan={tableColumns.length} ><p>Start of detection</p></td>
                }
              </tbody>
            </table>
          </div>
          <DetailModal
            show={detailSegment !== null}
            segment={detailSegment}
            onClose={() => {setDetailSegment(null)}}
          />
          {isLoading && <Loader center={true}/>}
        </div>
      </div>
    </div>
  )
}
