import { applyMiddleware, compose, createStore } from 'redux';
import sagaMiddlewareFactory from 'redux-saga';
import {rootSagas} from './sagas/root.sagas';
import {EnhancerOptions, composeWithDevTools} from 'redux-devtools-extension';
import { rootReducers, initialRootState } from './reducers/root.reducer';

const sagaMiddleware = sagaMiddlewareFactory();

const middlewares = [sagaMiddleware];

let composeEnhancers: typeof compose = compose;

if (process.env.NODE_ENV === 'development') {

	const composeDevToolsOptions: EnhancerOptions | undefined = {};
	composeEnhancers = composeWithDevTools(composeDevToolsOptions);
}

const enhancers = composeEnhancers(applyMiddleware(...middlewares));

export const store = createStore(rootReducers, initialRootState as any, enhancers);
sagaMiddleware.run(rootSagas);