import {FaCheck, FaMicrophoneSlash, FaMinus, FaMusic, FaRegStickyNote, FaTimes, FaVolumeMute} from "react-icons/fa";
import {RiAdvertisementFill, RiAdvertisementLine, RiVolumeVibrateFill} from "react-icons/ri";

export const SLATE_ICON = "slate";
export const ANTHEM_ICON = "anthem";
export const AD_ICON = "ad";
export const NO_RESULTS_ICON = "no-results";
export const NO_AUDIO_ICON = "no-audio-signal";
export const NO_COMMENTARY_ICON = "no-commentary";
export const MISMATCH_ICON = "mismatched-commentary-language";
export const POOR_QUALITY_ICON = "poor-audio-quality";
export const SUCCESSFUL_ICON = "detection-successful";

export default {
  [SLATE_ICON]: {
    icon: FaRegStickyNote,
    color: "infoColor",
    label: "Slate detected"
  },
  [ANTHEM_ICON]: {
    icon: FaMusic,
    color: "infoColor",
    label: "National anthem detected"
  },
  [AD_ICON]: {
    icon: RiAdvertisementLine,
    color: "infoColor",
    label: "Ad detected"
  },
  [NO_RESULTS_ICON]: {
    icon: FaMinus,
    color: "infoColor",
    label: "No detection ran"
  },
  [NO_AUDIO_ICON]: {
    icon: FaVolumeMute,
    color: "errorColor",
    label: "No audio signal"
  },
  [NO_COMMENTARY_ICON]: {
    icon: FaMicrophoneSlash,
    color: "errorColor",
    label: "No commentary"
  },
  [MISMATCH_ICON]: {
    icon: FaTimes,
    color: "errorColor",
    label: "Mismatched commentary language"
  },
  [POOR_QUALITY_ICON]: {
    icon: RiVolumeVibrateFill,
    color: "errorColor",
    label: "Poor audio quality"
  },
  [SUCCESSFUL_ICON]: {
    icon: FaCheck,
    color: "correctColor",
    label: "No issues"
  },
}