import { Action, ActionType,  PayloadAction } from 'typesafe-actions';
import {createAction} from '../utils/actions/action-creators.util';
import { CLEAR_AUTH, LOAD_AUTH, LOAD_AUTH_ERROR, LOAD_AUTH_SUCCESS } from '../constants/auth.constants';
import { AuthState } from '../reducers/auth.reducer';

export const authActions = {
	/**
	 * LOAD_AUTH
	 */
    loadAuth: (): Action<typeof LOAD_AUTH> => createAction(LOAD_AUTH),
	loadAuthError: (error: Error): PayloadAction<typeof LOAD_AUTH_ERROR, {error: typeof error}> =>
		createAction(LOAD_AUTH_ERROR, {error}),
    loadSuccess: (response: AuthState['credentials']): PayloadAction<typeof LOAD_AUTH_SUCCESS, {response: typeof response}> =>
		createAction(LOAD_AUTH_SUCCESS, {response}),

    /**
	 * CLEAR_AUTH
	 */
    clearAuth: (): Action<typeof CLEAR_AUTH> => createAction(CLEAR_AUTH)

};

export type authActions = ActionType<typeof authActions>;