import {BrowserRouter, Route, Switch} from "react-router-dom";
import NavBar from "./components/NavBar";
import Home from "./views/Home";
import Details from "./views/Details";
import Settings from "./views/Settings";
import Error404 from "./views/404";
import Amplify from 'aws-amplify';
import { withAuthenticator, AmplifySignOut } from '@aws-amplify/ui-react';
import SolutionManifest from "./services/SolutionManifest";
import {useAuth} from "./hooks/use-auth-hook";
import Search from "./views/Search";

Amplify.configure({
  Auth: {
    identityPoolId: SolutionManifest.Cognito.IdentityPoolId,
    region: SolutionManifest.Region,
    userPoolId: SolutionManifest.Cognito.UserPoolId,
    userPoolWebClientId: SolutionManifest.Cognito.ClientId,
    oauth:{
      domain: SolutionManifest.Cognito.UserPoolDomainName + ".auth." + SolutionManifest.Region + ".amazoncognito.com",
      scope: ["email", "openid", "aws.cognito.signin.user.admin"],
      responseType: "code",
      redirectSignIn: SolutionManifest.Cognito.RedirectUri,
      redirectSignOut: SolutionManifest.Cognito.RedirectUri
    }
  }
});

const App = () => {
  useAuth();

  return (
    <BrowserRouter>
      <div className="App">
        <div className='logoutWrapper'>
          <AmplifySignOut />
        </div>
        <NavBar />
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/details">
            <Details />
          </Route>
          <Route path="/settings">
            <Settings />
          </Route>
          <Route path="/search">
            <Search />
          </Route>
          <Route>
            <Error404/>
          </Route>
        </Switch>
      </div>
    </BrowserRouter>
  );
}

const federatedConfig = {
  oauthConfig: {
    customProvider: "Okta",
    label: "Sign in with Discovery SSO"
  }
};

export default withAuthenticator(App, {
  federated: federatedConfig
});

