import './index.css';

import {useState} from "react";
import TextInput from "../../components/TextInput";
import StyledButton from "../../components/StyledButton";
import {languageCodes} from "../../constants";
import {isoToLocaleDateTime} from "../../util/formatUtils";
import {useHistory} from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { apiActions } from '../../store/actions/api.actions';
import { apiSelectors } from '../../store/selectors/api.selectors';
import Loader from '../../components/Loader';

const itemsPerPage = 50;

export default function Search() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [search, setSearch] = useState<string>('');
  const [startTime, setStartTime] = useState<string>(isoToLocaleDateTime(Date.now() - 3600000));
  const [endTime, setEndTime] = useState<string>(isoToLocaleDateTime(Date.now()));
  const [error, setError] = useState<string>('');
  const [type, setType] = useState<'assetId' | 'streamId'>('assetId');
  const searchResult = useSelector(apiSelectors.searchResult);
  const searchResultOperation = useSelector(apiSelectors.searchOperation);

  const submitSearch = (nextToken?) => {
    const maxResults = itemsPerPage;
    const startTimeTime = new Date(startTime).getTime();
    const endTimeTime = new Date(endTime).getTime();
    const params = (type === 'assetId' ? {
      assetId: search,
      maxResults,
      nextToken
    } : {
      streamId: search,
      startTime: startTimeTime,
      endTime: endTimeTime,
      maxResults,
      nextToken
    });
    if (type === 'streamId') {
      if (endTimeTime <= startTimeTime) {
        setError('Date time range is invalid.');
        return;
      }
    }
    setError('');
    dispatch(apiActions.search({ options: params }));
  }

  const renderedItems = () => {
    return searchResult?.items?.map((i) => {
      const languages = (i.languageCodes || '').split(',')
        .map(x => (languageCodes.find(x0 => x0.languageCode === x) || {}).languageName)
        .filter(x => x)
        .join(', ');
      return (
        <tr className=""
            onClick={() => { history.push(`/details?assetId=${i.assetId}&startTime=${i.actualStartTime}&endTime=${i.actualEndTime}`)}}>
          <td className="">{i.assetName}</td>
          <td className="">{i.assetId}</td>
          <td className="">{i.streamId}</td>
          <td className="">{i.actualStartTime ? (new Date(i.actualStartTime).toISOString()) : '--'}</td>
          <td className="">{i.actualEndTime ? (new Date(i.actualEndTime).toISOString()) : '--'}</td>
          <td className="">{languages || '--'}</td>
        </tr>)
    });
  }

  // @ts-ignore
  return (
    <div className="pageWrapper page">
      <div className="searchContentWrapper">
        {error !== '' &&
        <p className="searchError">
          {error}
        </p>
        }
        <div className="searchHeaderWrapper">
          <p className="lead searchHeaderTitle">Search a specific event by <strong>AssetId</strong> or search events by <strong>StreamId</strong> (channel) within specific date/time range.</p>
          <div className="searchHeaderRow">
            <div className="buttonWrapper">
              <StyledButton className={`${type === 'assetId' ? 'selected':''}`} onClick={() => setType('assetId')}>Asset ID</StyledButton>
              <StyledButton className={`${type === 'streamId' ? 'selected':''}`} onClick={() => setType('streamId')}>Stream ID</StyledButton>
            </div>
            <div className="searchBarWrapper">
              <div className="searchInputWrapper">
                <TextInput value={search} onChange={setSearch} placeholder={type === 'assetId' ? 'Asset ID' : 'Stream ID'}/>
              </div>
              <StyledButton onClick={() => submitSearch()}>Search</StyledButton>
            </div>
            {/*results per page selection*/}
          </div>
          {type === 'streamId' &&
            <div className="searchHeaderRow">
              <div className="timeWrapper">
                <p>Between</p>
                <input type="datetime-local" value={startTime} onChange={e => setStartTime(e.target.value)}/>
                <p>and</p>
                <input type="datetime-local" value={endTime} onChange={e => setEndTime(e.target.value)}/>
              </div>
            </div>
          }
        </div>
        <div className="searchResultsWrapper">
          
          {searchResult && searchResult.items.length > 0 ? ( <div className="table-responsive">

            <table className="table table-hover align-middle">
              <thead>
              <tr>
                <th>AssetName</th>
                <th>AssetId</th>
                <th>StreamId</th>
                <th>ActualStartTime</th>
                <th>ActualEndTime</th>
                <th>LanguageCodes</th>
              </tr>
              </thead>
              <tbody>
              {renderedItems()}
              </tbody>
            </table>

            {searchResult?.nextToken !== undefined &&
            <div className="searchPaginationWrapper">
              <StyledButton onClick={() => submitSearch(searchResult?.nextToken)}>Show more</StyledButton>
            </div>
            }
          </div>): <div className="alert alert-secondary" role="alert">No results</div>}
          { searchResultOperation?.status?.isLoading && <Loader center={true}/>} 
        </div>
      </div>
    </div>
  );
}
