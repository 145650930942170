import {createSelector} from 'reselect';
import {RootState} from '../reducers/root.reducer';
import { createSignedUrlStateKey, S3BucketKey } from '../reducers/s3.reducer';
import { createOperationResultSelector } from '../utils/selectors/selectors.util';

const s3Selector = (state: RootState) => {
	return state?.s3;
};

const s3SignedUrlsSelector = createSelector(s3Selector, (state) => state.signedUrls);

export const createS3SignedUrlSelector = (bucketKey: S3BucketKey) =>  createSelector(s3SignedUrlsSelector, (state) => {
		return state?.[createSignedUrlStateKey(bucketKey)]!;
	});

export const createS3SignedUrlResultSelector = (bucketKey: S3BucketKey) => {
	const signedUrlOperationSelector = createSelector(s3SignedUrlsSelector, (state) => {
		return state?.[createSignedUrlStateKey(bucketKey)]!;
	});

	return createOperationResultSelector(signedUrlOperationSelector);
}

export const s3Selectors = {
};
