import {FaCircle} from "react-icons/fa";
import {PERCEPTUAL_DISTANCE, PIXEL_DISTANCE} from "../constants";
import statusIcons, {
  MISMATCH_ICON,
  NO_AUDIO_ICON,
  NO_COMMENTARY_ICON,
  NO_RESULTS_ICON, POOR_QUALITY_ICON,
  SLATE_ICON, SUCCESSFUL_ICON, AD_ICON
} from "../types/StatusIconConstants";
import {createElement} from "react";

interface Props {
  langTrack: { [key: string]: any},
  slate?: boolean,
  ad?: boolean,
  isoCode: string,
  source: 'mosaicView' | 'detailView'
}

export default function StatusIcon(props: Props) {
  let statusIcon;
  // case 1: slate detected
  if(props.slate) {
    statusIcon = statusIcons[SLATE_ICON];
  }
  // case 2:
  else if (props.ad) {
    statusIcon = statusIcons[AD_ICON];
  }
  // case 3: TODO anthem icon
  // case 4: no detection
  else if (!props.langTrack || props.langTrack.detectedLanguage === undefined) {
    statusIcon = (props.source === 'mosaicView' ? { icon: FaCircle, color: 'infoColor', title: "No results"} : statusIcons[NO_RESULTS_ICON]);
  }
  // case 5: track has no signal
  else if (props.langTrack.signal.perceptualDistance < PERCEPTUAL_DISTANCE
    && props.langTrack.signal.pixelDistance < PIXEL_DISTANCE) {
    statusIcon = statusIcons[NO_AUDIO_ICON];
  }
  // case 6: track has no commentary
  else if (props.langTrack.ambientSound
    && props.langTrack.ambientSound.perceptualDistance < PERCEPTUAL_DISTANCE
    && props.langTrack.ambientSound.pixelDistance < PIXEL_DISTANCE) {
    statusIcon = statusIcons[NO_COMMENTARY_ICON];
  }
  // case 7: detection failed or mismatched language
  else if (props.langTrack.detectedLanguage === false
    // @ts-ignore
    || props.langTrack.detectedLanguage.slice(0, 2) !== props.isoCode) {
    statusIcon = statusIcons[MISMATCH_ICON];
  }
  // case 8: audio issues detected
  else if(props.langTrack.audioIssue) {
    statusIcon = statusIcons[POOR_QUALITY_ICON];
  }
  // case 9: all good
  else {
    statusIcon = statusIcons[SUCCESSFUL_ICON];
  }
  const cssClass = props.ad ? `ad-icon ${statusIcon.color}` : statusIcon.color;
  return createElement(statusIcon.icon, { className: cssClass, title: statusIcon.label });
}
