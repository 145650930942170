/**
 * IOT_CONNECT
 */
export const IOT_CONNECT = 'IOT.IOT_CONNECT';
export const IOT_CONNECT_SUCCESS = 'IOT.IOT_CONNECT_SUCCESS';
export const IOT_CONNECT_ERROR = 'IOT.IOT_CONNECT_ERROR';

/**
 * IOT_DISCONNECT
 */
 export const IOT_DISCONNECT = 'IOT.DISCONNECT';
 export const IOT_DISCONNECT_SUCCESS = 'IOT.DISCONNECT_SUCCESS';
 export const IOT_DISCONNECT_ERROR = 'IOT.DISCONNECT_ERROR';

/**
 * IOT_REGISTER_LISTENER
 */
export const IOT_REGISTER_LISTENER = 'IOT.UNREGISTER_LISTENER';
export const IOT_REGISTER_LISTENER_SUCCESS = 'IOT.REGISTER_LISTENER_SUCCESS';
export const IOT_REGISTER_LISTENER_ERROR = 'IOT.REGISTER_LISTENER_ERROR';