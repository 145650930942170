import Stream from '../types/Stream'
import './FilterBar.css'
import { useEffect, useState } from 'react'
import Loader from './Loader'
import { withErrorBoundary } from 'react-error-boundary'
import ErrorFallback from './ErrorFallback'
import FilterRow from './FilterRow'
import { CHANNEL_MAPPING } from "../constants";

export default withErrorBoundary(FilterBar, {
  FallbackComponent: ErrorFallback,
  onError(error, info) {
    console.log(error);
  },
})

function FilterBar (props: {
  streams: Stream[],
  onChangeFilter: Function,
  filters: String[],
  showLive: boolean
  showVirtual: boolean
  showEvent: boolean
  showLinear: boolean
  showAventus: boolean
  showAventusLinear: boolean
  liveStatusByChannel: {}
  handleStatuses: Function
}) {

  const [selection, setSelection] = useState<String[]>(props.filters)
  let streams = props.streams.sort(function (a, b) {
    return a.streamId.localeCompare(b.streamId)
  })
  const handleClick = (s: Stream) => {
    if (selection.includes(s.streamId)) {
      const newSelection = selection
      newSelection.splice(selection.findIndex(x => x === s.streamId), 1)
      setSelection([...newSelection])
    } else {
      setSelection([...selection, s.streamId])
    }
  }
  const handleClearFilterClick = () => {
    setSelection([])
  }

  useEffect(() => {
    props.onChangeFilter(selection)
    localStorage.setItem('filters', JSON.stringify(selection))
  }, [selection])

  const now = Date.now();

  return <div className="card">
    <div className="card-header text-left">
      Filter channels
    </div>
    <div className="btn-group-vertical">
      <button className="btn btn-danger text-left" onClick={()=>handleClearFilterClick()} disabled={selection.length == 0}>Clear selection</button>

      {streams.length == 0 ? (<Loader/>) : streams.map((s) => {
        if(props.showLinear && ( s.streamId.startsWith('esp-20') || s.streamId.startsWith('esp-30')) && s.streamId.split('-')[1].length === 5){
          return null;
        }

        if(props.showAventusLinear && (s.streamId.split('-')[1].length === 5 || s.streamId.startsWith('eurosport'))){
          return null;
        }

        if(props.showAventus && ((s.streamId.split('-')[1].length < 5) || (!s.streamId.startsWith('esp-')))) {
          return null;
        }

        if((s.streamId.split('-')[1].length === 5) && (s.streamId.startsWith('eurosport'))){
          return null;
        }

        if((props.liveStatusByChannel[s.streamId] == "END" || props.liveStatusByChannel[s.streamId] == "SCHEDULED" || props.liveStatusByChannel[s.streamId] == "REPLAY") && props.showLive){
          return null;
        }

        return (
          <div key={s.streamId}
            className={`btn btn-outline-primary filterItem text-left position-relative ${selection.includes(s.streamId) ? 'active' : ''}`}
            onClick={() => handleClick(s)}>
            {<FilterRow selection={selection} onChangeFilter={props.onChangeFilter} stream={s} handleStatuses={props.handleStatuses} />}
          </div>
        )
      })}
    </div>
  </div>
}
