export function randomHexstring() {
  const rnd = new Uint32Array(1);
  // @ts-ignore
  (window.crypto || window.msCrypto).getRandomValues(rnd);
  return rnd[0].toString(16);
}

export function readableDuration(durationInMs = 0) {
  const HH = Math.floor(durationInMs / 3600000);
  const MM = Math.floor((durationInMs % 3600000) / 60000);
  const SS = Math.floor((durationInMs % 60000) / 1000);
  const mmm = Math.ceil(durationInMs % 1000);
  let returnVal = '';
  if(HH > 0) {
    returnVal += `${HH.toString().padStart(2, '0')}:`;
  }
  if(MM > 0) {
    returnVal += `${MM.toString().padStart(2, '0')}:`;
  }
  return returnVal + `${SS.toString().padStart(2, '0')}.${mmm.toString().padStart(3, '0')}`;
}

export function isoToLocaleDateTime(datetime = Date.now()) {
  const date = new Date(datetime);
  return [
    date.getFullYear(),
    '-',
    String(date.getMonth() + 1).padStart(2, '0'),
    '-',
    String(date.getDate()).padStart(2, '0'),
    'T',
    String(date.getHours()).padStart(2, '0'),
    ':',
    String(date.getMinutes()).padStart(2, '0'),
  ].join('');
}