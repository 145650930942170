import {combineReducers} from 'redux';
import {StateType} from 'typesafe-actions';

import { authReducers, initialAuthState } from './auth.reducer';
import { apiReducers, initialAPIState } from './api.reducer';
import { iotReducers, initialIoTState } from './iot.reducer';
import { s3Reducers, initialS3State } from './s3.reducer';

export const rootReducers = combineReducers({
    auth: authReducers,
    api: apiReducers,
    iot: iotReducers,
    s3: s3Reducers,
});

export type RootReducers = ReturnType<typeof rootReducers>;

export const initialRootState = {
	...initialAPIState,
    ...initialAuthState,
    ...initialIoTState,
    ...initialS3State,
};

export type RootState = StateType<typeof rootReducers>;
