import { Selector, createSelector } from 'reselect';

import { OperationState } from '../../types/reducers/OperationStatus';

export const createOperationResultSelector = <RootState, Current, Err>(
	operationSelectors: Selector<RootState, OperationState<Current, Err> | null>
) => createSelector(operationSelectors, (state) => state?.current);

export const createOperationStatusSelector = <RootState, Current, Err>(
	operationSelectors: Selector<RootState, OperationState<Current, Err> | null>
) => createSelector(operationSelectors, (state) => state?.status);

export const createOperationErrorSelector = <RootState, Current, Err>(
	operationSelectors: Selector<RootState, OperationState<Current, Err> | null>
) => createSelector(operationSelectors, (state) => state?.status?.error);

export const createOperationErrorParsedSelector = <RootState, Err>(
	operationErrorSelector: Selector<RootState, Err | undefined>
) =>
    createSelector(operationErrorSelector, (error: any) => {
        return error?.message || error?.response?.data?.message;
    });
