import {useEffect, useState} from 'react'
import DetectionResult from '../types/DetectionResult'
import { useDispatch, useSelector } from 'react-redux';
import { createS3SignedUrlResultSelector } from '../store/selectors/s3.selectors';
import { s3Actions } from '../store/actions/s3.actions';
import { apiActions } from '../store/actions/api.actions';
import { apiSelectors } from '../store/selectors/api.selectors';
import { getPlayStateFromSchedule } from '../util/scheduleHelper';
import SchedulePlayState from './SchedulePlayState';
import './DetailsHeader.css';
import { authSelectors } from '../store/selectors/auth.selectors';
import AlarmPreset from "../types/AlarmPreset";
import SportTypeBadge from "./SportTypeBadge";


export default function DetailsHeader (props: { assetId: string | null, startTime: number, endTime: number, now: number, detectionResults: DetectionResult[], isLoading: boolean, playoutId?: string; streamId?: string; }) {
  const dispatch = useDispatch()
  const s3SignedImageUrl = useSelector(createS3SignedUrlResultSelector({ bucket: props?.detectionResults[0]?.languageIdBucket, key: props?.detectionResults?.[0]?.frame?.key}));
  const alarmPresets = useSelector(apiSelectors.alarmPresets());

  const isAuthenticated = useSelector(authSelectors.isAuthenticated);
  const scheduleDetails = useSelector(apiSelectors.scheduleDetailsResult(props.assetId || ''));
  const schedulePlayState = getPlayStateFromSchedule(scheduleDetails);

  let assetName = '';
  if(props?.detectionResults?.length > 0) {
    assetName = props?.detectionResults[0]?.assetName;
  }

  let liveStatus = '';
  if (props.now > props.endTime)
    liveStatus = 'END';
  else if(props.now < props.startTime)
    liveStatus = 'SCHEDULED';
  else if (props?.detectionResults[0]?.broadcastType === 'Replay')
    liveStatus = 'REPLAY';
  else if (props.now >= props.startTime && props.now <= props.endTime)
    liveStatus = 'LIVE';

  useEffect(() => {
    const results = props?.detectionResults;
    if(results.length > 0) {
      assetName = results[0].assetName;
    }
    if (results?.length > 0 && !s3SignedImageUrl) {
      dispatch(s3Actions.signUrl({ bucket: results[0]?.languageIdBucket, key: results[0]?.frame?.key }))
    }
  }, [props?.detectionResults]);

  useEffect(() => {
    if (!scheduleDetails && props.assetId && isAuthenticated === true) {
      dispatch(apiActions.getScheduleDetails({ assetId: props.assetId }));
    }


  }, [isAuthenticated]);

  useEffect(() => {
    if(isAuthenticated === true && scheduleDetails?.sportType){
      dispatch(apiActions.getAlarmPresets());
    }
  }, [scheduleDetails]);

  const [presetLangSpec, setPresetLangSpec] = useState<AlarmPreset>();
  const [presetAudioSpec, setPresetAudioSpec] = useState<AlarmPreset>();

  useEffect(() => {
    alarmPresets?.sports.map((s) => {
      let presets = alarmPresets?.presets;
      let langSpec = presets.find(p => p.sk === s.langSpec)
      let audioSpec = presets.find(p => p.sk === s.audioSpec)

      setPresetLangSpec(langSpec);
      setPresetAudioSpec(audioSpec);
    });

  }, [alarmPresets]);

  return <div className="details-header row">
      <div className="col-md-6">
          <div className="align-middle">
              <h3>{liveStatus == 'END' &&
              <span className="badge bg-dark">End</span>}{liveStatus == 'SCHEDULED' &&
              <span className="badge bg-warning text-dark">Scheduled</span>}{liveStatus == 'LIVE' &&
              <span className="badge bg-success">Live</span>}{liveStatus == 'REPLAY' &&
              <span className="badge bg-primary text-light">Replay</span>} {props.assetId}</h3>
              <h3>{assetName}</h3>
              <SchedulePlayState schedule={scheduleDetails}/>
              {props.startTime !== 0 && props.endTime !== 0 &&
              <div>
                  <h5>{props.startTime !== 0 && new Date(props.startTime).toISOString()} - {props.endTime !== 0 && new Date(props.endTime).toISOString()}</h5>
              </div>
              }
              <div className="col">
              <div className="btn-group" role="group">

                  <button type="button" className="btn btn-outline-secondary" onClick={() => {
                      if (window.confirm('Are you sure you wish to mute PD alerts?')) dispatch(apiActions.postConfig({
                          assetId: props.assetId || '',
                          action: 'mute'
                      }))
                  }} disabled={schedulePlayState?.muted || props.now > props.endTime}>Mute
                  </button>
                  <button type="button" className="btn btn-outline-secondary" onClick={() => {
                      if (window.confirm('Are you sure you wish to enable PD alerts?')) dispatch(apiActions.postConfig({
                          assetId: props.assetId || '',
                          action: 'unmute'
                      }))
                  }} disabled={schedulePlayState?.unmuted || props.now > props.endTime}>Unmute
                  </button>
                  <button type="button" className="btn btn-outline-secondary" onClick={() => {
                      if (window.confirm('Are you sure you wish to pause language detection?')) dispatch(apiActions.postConfig({
                          assetId: props.assetId || '',
                          action: 'pause'
                      }))
                  }} disabled={schedulePlayState?.paused || props.now > props.endTime}>Pause
                  </button>
                  <button type="button" className="btn btn-outline-secondary" onClick={() => {
                      if (window.confirm('Are you sure you wish to enable language detection?')) dispatch(apiActions.postConfig({
                          assetId: props.assetId || '',
                          action: 'resume'
                      }))
                  }} disabled={schedulePlayState?.resumed || props.now > props.endTime}>Resume
                  </button>
              </div>
              </div>
              <div className="col mt-3">
              <div className="btn-group" role="group">

                  <button type="button" className="btn btn-outline-secondary"
                          onClick={() => window.open(`https://inflow.eurosport.com/program/${props.assetId}`, '_blank')?.focus()}>InFlow
                  </button>
                  <button type="button" className="btn btn-outline-secondary"
                          onClick={() => window.open(`https://console.monitoring.vdp.h264.io/details/time_shifted/${props.playoutId}/${props.streamId}`, '_blank')?.focus()}>VDP Timeshifted
                  </button>
                  <button type="button" className="btn btn-outline-secondary"
                          onClick={() => window.open(` https://console.monitoring.vdp.h264.io/details/stream/${props.streamId}`, '_blank')?.focus()}>VDP Live Stream
                  </button>
              </div>
              </div>
          </div>
      </div>
      <div className="col-md-6">
          <div className="image position-relative">
              {!props.isLoading &&
              <>{s3SignedImageUrl &&
              <img alt={`Channel ${props.assetId} thumbnail`} className=" img-fluid" src={s3SignedImageUrl}/>}
                  {scheduleDetails?.sportType &&
                  <SportTypeBadge scheduleDetails={scheduleDetails} presetLangSpec={presetLangSpec}
                                  presetAudioSpec={presetAudioSpec}/>}</>
              }
          </div>
      </div>
  </div>
}
