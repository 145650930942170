import {createSelector} from 'reselect';
import {RootState} from '../reducers/root.reducer';
import { createOperationResultSelector } from '../utils/selectors/selectors.util';

const iotSelector = (state: RootState) => {
	return state?.iot;
};

const iotIsConnectedSelector = createSelector(iotSelector, (state) => {
	return state?.isConnected;
});

const iotIsConnectedResultSelector = createOperationResultSelector(iotIsConnectedSelector);

export const iotSelectors = {
	isConnected: iotIsConnectedResultSelector
};