const SolutionManifest = {
  "SolutionId": "aicommentary",
  "Version": "187a696b10f883ff451780083d9efeeeb5bf7fd2",
  "Region": "eu-west-1",
  "ApiEndpoint": "https://4ec3j2deue.execute-api.eu-west-1.amazonaws.com/demo",
  "DisableCustomLanguages": "false",
  "LastUpdated": "2024-06-18T20:41:38.502Z",
  "Cognito": {
    "UserPoolId": "eu-west-1_4XrURTzo1",
    "ClientId": "3rd35arnjbik82rlon6o5pea8d",
    "IdentityPoolId": "eu-west-1:f5416ad9-5159-471f-bb3c-c36671e86a75",
    "RedirectUri": "https://dacc.vdp.h264.io",
    "UserPoolDomainName": "aicommentary-discovery"
  },
  "StackName": "aicommentary-prod",
  "IoT": {
    "Host": "aoex7azatahjm-ats.iot.eu-west-1.amazonaws.com",
    "Topic": "languagecode/status/02baaece74b3"
  }
};

export default SolutionManifest;
