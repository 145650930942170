import { Schedule, SchedulePlayStates } from '../types/Stream';

export const getPlayStateFromSchedule = (schedule?: Schedule) => {
    const {
        pauseConfig: { endTime: endTimePaused } = { endTime: undefined },
        muteConfig: { endTime: endTimeMuted } = { endTime: undefined }
    } = schedule?.config || {};

    const isPaused = endTimePaused ? endTimePaused > new Date().getTime() : false;
    const isMuted = endTimeMuted ? endTimeMuted > new Date().getTime() : false;

    return {
        [SchedulePlayStates.Muted]: isMuted,
        [SchedulePlayStates.Unmuted]: !isMuted,
        [SchedulePlayStates.Paused]: isPaused,
        [SchedulePlayStates.Resumed]: !isPaused
    };
}