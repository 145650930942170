import {createSelector} from 'reselect';
import {RootState} from '../reducers/root.reducer';
import { createOperationResultSelector } from '../utils/selectors/selectors.util';

const authSelector = (state: RootState) => {
	return state?.auth;
};

const authCredentialsSelector = createSelector(authSelector, (state) => {
	return state?.credentials;
});

const authCredentialsResultSelector = createOperationResultSelector(authCredentialsSelector);

const authIsAuthenticatedSelector = createSelector(authCredentialsResultSelector, (state) => {
	return !!state?.accessKeyId?.length;
});

export const authSelectors = {
	credentials: authCredentialsResultSelector,
	isAuthenticated: authIsAuthenticatedSelector,
};