import {all, call, put, select, takeEvery} from 'redux-saga/effects';
import SigV4Client from "../../util/signer.js";
import {Auth} from 'aws-amplify';
import SolutionManifest from "../../services/SolutionManifest";
import {
  API_ATTACH_IOT,
  API_GET_ALARM_PRESETS,
  API_GET_SCHEDULE_DETAILS,
  API_GET_SCHEDULE_RECORDS,
  API_GET_TODAY_SCHEDULES,
  API_POST_CONFIG,
  API_SEARCH
} from '../constants/api.constants';
import { apiActions } from '../actions/api.actions';

const ONE_DAY_IN_MSECS = 86400000;

const pack = (data) => {
  return (data === undefined)
    ? data
    : JSON.parse(JSON.stringify(data));
}

const signRequest = (method, endpoint, path, query, body, credentials) => {
  const signer = new SigV4Client({
    accessKey: credentials.accessKeyId,
    secretKey: credentials.secretAccessKey,
    sessionToken: credentials.sessionToken,
    region: 'eu-west-1',
    serviceName: 'execute-api',
    endpoint,
  });

  return signer.signRequest({
    method,
    path,
    headers: {
      'Content-Type': 'application/json',
    },
    queryParams: query,
    body: (typeof body === 'string') ? body : JSON.stringify(body),
  });
}

const authHttpRequest = (method, endpoint, query = {}, body = '', credentials) => {
  return new Promise(async (resolve, reject) => {
    const request = new XMLHttpRequest();

    const {url, headers} = await signRequest(method, endpoint, '', query, body, credentials)
    request.open(method, url, true);

    Object.keys(headers).forEach((x) => {
      request.setRequestHeader(x, headers[x]);
    });

    request.withCredentials = false;

    request.onerror = e => reject(e);

    request.onabort = e => reject(e);

    request.onreadystatechange = () => {
      if (request.readyState === XMLHttpRequest.DONE) {
        if (request.status === 200) {
          resolve(JSON.parse(request.responseText || '{}'));
        } else if (request.status >= 400) {
          reject(new Error(`${request.status} - ${request.responseURL}`));
        }
      }
    };

    request.send((typeof body === 'string')
      ? body
      : JSON.stringify(body));
  });
}

export const apiSagas = () => {
  function* getScheduleRecordsSaga(action: ReturnType<typeof apiActions['getScheduleRecords']>) {
    try {

      yield call(
        Auth.currentAuthenticatedUser.bind(Auth)
      );

      //if we're here then we are good to fetch creds
      const credentials = yield call(
        Auth.currentCredentials.bind(Auth)
      );

      const {
        assetId,
        options
      } = action?.payload?.request;
      const result = yield call(
          authHttpRequest,
          'GET',
          `${SolutionManifest.ApiEndpoint}/records`,
          pack({
              assetId,
              ...options,
          }),
          undefined,
          credentials
      );
  
      yield put(apiActions.getScheduleRecordsSuccess(action?.payload?.request, result, !!options?.nextToken));
    } catch (error) {
      console.error(`An error occurred while getting schedule records`, error); 
      yield put(apiActions.getScheduleRecordsError(action?.payload?.request, error));
    }
  }

  function* getTodaySchedulesSaga() {
    try {
      yield call(
        Auth.currentAuthenticatedUser.bind(Auth)
      );

      //if we're here then we are good to fetch creds
      const credentials = yield call(
        Auth.currentCredentials.bind(Auth)
      );

      const ONE_HOUR = 3600 * 1000;
      const TWELVE_HOURS = 12 * ONE_HOUR;
      // Rounding to Hour: 16:45:30 -> 16:00:00
      const tnow = Math.floor(Date.now() / ONE_HOUR) * ONE_HOUR;
      const tsta = tnow - TWELVE_HOURS;
      const tend = tnow + TWELVE_HOURS;
      const response = yield call(
        authHttpRequest,
        'GET',
        `${SolutionManifest.ApiEndpoint}/schedules`,
        {
          startTime: tsta,
          endTime: tend,
        },
        undefined,
        credentials
      );
  
      yield put(apiActions.getTodaySchedulesSuccess(response));
    } catch (error) {
      console.error(`An error occurred while getting today's schedules`, error); 
      yield put(apiActions.getTodaySchedulesError(error));
    }
  }

  function* getScheduleDetailsSaga(action: ReturnType<typeof apiActions['getScheduleDetails']>) {
    const { assetId } = action.payload.request;
    try {
      yield call(
        Auth.currentAuthenticatedUser.bind(Auth)
      );

      //if we're here then we are good to fetch creds
      const credentials = yield call(
        Auth.currentCredentials.bind(Auth)
      );


      const response = yield call(
        authHttpRequest,
        'GET',
        `${SolutionManifest.ApiEndpoint}/schedule`,
        {
          assetId
        },
        undefined,
        credentials
      );
  
      yield put(apiActions.getScheduleDetailsSuccess(assetId, response));
      
    } catch (error) {
      console.error(`An error occurred while getting schedule details for asset id '${assetId}'`, error); 
      yield put(apiActions.getScheduleDetailsError(assetId, error));
    }
  }

  function* attachIoTSaga() {
    try {
      yield call(
        Auth.currentAuthenticatedUser.bind(Auth)
      );

      //if we're here then we are good to fetch creds
      const credentials = yield call(
        Auth.currentCredentials.bind(Auth)
      );

      const response = yield call(
        authHttpRequest,
        'POST',
        `${SolutionManifest.ApiEndpoint}/iot`,
        undefined,
        undefined,
        credentials
      );
  
      yield put(apiActions.attachIotSuccess(response));
    } catch (error) {
      console.error('An error occurred while attaching to IoT', error); 
      yield put(apiActions.attachIotError(error));
    }
  }

  function* searchSaga(action: ReturnType<typeof apiActions['search']>) {
    try {
      yield call(
        Auth.currentAuthenticatedUser.bind(Auth)
      );

      //if we're here then we are good to fetch creds
      const credentials = yield call(
        Auth.currentCredentials.bind(Auth)
      );

      const options = action.payload.request.options as any;
      const response = yield call(
        authHttpRequest,
        'GET',
        `${SolutionManifest.ApiEndpoint}/search`,
        pack(options),
        undefined,
        credentials
      );
  
      yield put(apiActions.searchSuccess(response));
      if (!options.nextToken) {
        window.scrollTo(0, 0);
      }
      
    } catch (error) {
      console.error('An error occurred during search', error); 
      yield put(apiActions.searchError(error));
    }
  }

  function* postConfigSaga({ payload: { request: { assetId, action } } }: ReturnType<typeof apiActions['postConfig']>) {
    try {
      yield call(
        Auth.currentAuthenticatedUser.bind(Auth)
      );

      //if we're here then we are good to fetch creds
      const credentials = yield call(
        Auth.currentCredentials.bind(Auth)
      );

       yield call(
        authHttpRequest,
        'POST',
        `${SolutionManifest.ApiEndpoint}/config`,
        pack({
          assetId,
          action,
        }),
        undefined,
        credentials
      );
  
      yield put(apiActions.postConfigSuccess());
      yield put(apiActions.getScheduleDetails({ assetId }));
    } catch (error) {
      console.error('An error occurred when posting the config', error); 
      yield put(apiActions.postConfigError(error));
    }
  }

  function* getAlarmPresetsSaga(action: ReturnType<typeof apiActions['getAlarmPresets']>) {
    try {

      yield call(
        Auth.currentAuthenticatedUser.bind(Auth)
      );

      //if we're here then we are good to fetch creds
      const credentials = yield call(
        Auth.currentCredentials.bind(Auth)
      );

      const result = yield call(
        authHttpRequest,
        'GET',
        `${SolutionManifest.ApiEndpoint}/alarmsSettings`,
        {},
        undefined,
        credentials
      );

      yield put(apiActions.getAlarmPresetsSuccess(result));
    } catch (error) {
      console.error(`An error occurred while getting alarm presets`, error);
      yield put(apiActions.getAlarmPresetsError(error));
    }
  }

  return {
    watcher: function* () {
      yield all([takeEvery(API_GET_SCHEDULE_RECORDS, getScheduleRecordsSaga)]);
      yield all([takeEvery(API_GET_TODAY_SCHEDULES, getTodaySchedulesSaga)]);
      yield all([takeEvery(API_GET_SCHEDULE_DETAILS, getScheduleDetailsSaga)]);
      yield all([takeEvery(API_ATTACH_IOT, attachIoTSaga)]);
      yield all([takeEvery(API_SEARCH, searchSaga)]);
      yield all([takeEvery(API_POST_CONFIG, postConfigSaga)]);
      yield all([takeEvery(API_GET_ALARM_PRESETS, getAlarmPresetsSaga)]);
    }
  }
}
