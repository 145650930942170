import Stream from "../types/Stream";
import DetectionResult from "../types/DetectionResult";
import { useHistory } from "react-router-dom";
import MoasaicStatusBar from "./MosaicStatusBar";
import { useEffect } from "react";
import './MosaicStream.css';
import { ISO639_2_ISO639_1, CHANNEL_MAPPING } from "../constants";
import { PERCEPTUAL_DISTANCE, PIXEL_DISTANCE } from "../constants";
import { useDispatch, useSelector } from "react-redux";
import { apiActions } from "../store/actions/api.actions";
import { apiSelectors } from "../store/selectors/api.selectors";
import { s3Actions } from "../store/actions/s3.actions";
import { createS3SignedUrlSelector } from "../store/selectors/s3.selectors";
import SchedulePlayState from './SchedulePlayState';
import { usePrevious } from '../hooks/use-previous-hook';
import ImageLoader from "./ImageLoader";
import { withErrorBoundary } from 'react-error-boundary'
import ErrorFallback from './ErrorFallback'

interface Props {
  stream: Stream,
  results: DetectionResult
  showLive: boolean
  showVirtual: boolean
  showEvent: boolean
  showLinear: boolean
  showAventus: boolean
  showAventusLinear: boolean
  liveStatusByChannel: {}
}
export default withErrorBoundary(MosaicStream, {
  FallbackComponent: ErrorFallback,
  onError(error, info) {
    console.log(error);
  },
})

function MosaicStream(props: Props) {
  const history = useHistory();
  const dispatch = useDispatch();

  const bestProgramMatch = () => {
    const now = Date.now();
    /* find current program */
    let program = props.stream.schedules.find(x =>
      x.actualStartTime <= now && x.actualEndTime >= now);
    if (program) {
      return program;
    }
    /* find next available program */
    program = props.stream.schedules.find(x =>
      x.actualStartTime > now);
    if (program) {
      return program;
    }
    /* find last program that has already aired */
    return props.stream.schedules[props.stream.schedules.length - 1];
  }

  const bestProgramMatched = bestProgramMatch();
  const lastRecords = useSelector(apiSelectors.scheduleRecordsItems(JSON.stringify({

    assetId: bestProgramMatched.assetId,
    type: 'home',
    ...{
      startTime: bestProgramMatched.actualStartTime,
      endTime: bestProgramMatched.actualEndTime,
      maxResults: 1
    }
  })));

  const result = props.results || lastRecords?.[0];

  const s3SignedUrlThumbnailBucketKey = { bucket: result?.languageIdBucket, key: result?.frame?.key };

  const prevS3SignedUrlThumbnailBucketKey = usePrevious(s3SignedUrlThumbnailBucketKey);
  const s3SignedUrlThumbnailOperation = useSelector(createS3SignedUrlSelector(s3SignedUrlThumbnailBucketKey));
  let scheduleDetails;
   scheduleDetails = useSelector(apiSelectors.scheduleDetailsResult(bestProgramMatch().assetId));


  useEffect(() => {
    const bucket = s3SignedUrlThumbnailBucketKey?.bucket;
    const key = s3SignedUrlThumbnailBucketKey?.key;
    const didChangeToTrueBucket = !!bucket && bucket !== prevS3SignedUrlThumbnailBucketKey?.bucket;
    const didChangeToTrueKey = !!key && key !== prevS3SignedUrlThumbnailBucketKey?.key;

    if ((didChangeToTrueBucket || didChangeToTrueKey) && !s3SignedUrlThumbnailOperation?.current) {
      dispatch(s3Actions.signUrl(s3SignedUrlThumbnailBucketKey));
    }
  }, [s3SignedUrlThumbnailBucketKey]);

  useEffect(() => {
    if(props.stream.schedules.length > 0 && bestProgramMatched?.assetId) {
      if (!scheduleDetails) {
        dispatch(apiActions.getScheduleDetails({ assetId: bestProgramMatched.assetId }));
      }
      if (!lastRecords?.length) {
        dispatch(apiActions.getScheduleRecords({
          type: 'home',
          assetId: bestProgramMatched.assetId,
          options: {
            startTime: bestProgramMatched.actualStartTime,
            endTime: bestProgramMatched.actualEndTime,
            maxResults: 1
          }
        }));
      }
    }
  }, [bestProgramMatched])

  const hasError = (): boolean => {
    if(!result) return false;
    if(result.ad) return false;
    for(const a of result.audio) {
      const isoCode = ISO639_2_ISO639_1[a.language];
      if (a.detectedLanguage === undefined) {
        return true
      }
      if (a.detectedLanguage === false
        // @ts-ignore
        || a.detectedLanguage.slice(0, 2) !== isoCode) {
        return true;
      }
      if (a.signal.perceptualDistance < PERCEPTUAL_DISTANCE
        && a.signal.pixelDistance < PIXEL_DISTANCE) {
        return true;
      }
      if (a.ambientSound
        && a.ambientSound.perceptualDistance < PERCEPTUAL_DISTANCE
        && a.ambientSound.pixelDistance < PIXEL_DISTANCE) {
        return true;
      }
    }
    return false;
  }

  if(!scheduleDetails || scheduleDetails == undefined) {
    return null;
  }

  const now = Date.now();

  // Handling overlapping scenarios
  if(props.results && scheduleDetails) {
    if(props.results.assetId != scheduleDetails.assetId){
      const now = Date.now();

      //  In case the there are overlapping programs, then we choose the program with earlier startTime
      const isLiveDetectionResults = props.results.actualStartTime <= now && props.results.actualEndTime >= now;
      const isLiveScheduledDetails = scheduleDetails.actualStartTime <= now && scheduleDetails.actualEndTime >= now;
      if(isLiveDetectionResults && isLiveScheduledDetails) {
        console.log("Overlapping programs: " + props.results.assetId + " , " + scheduleDetails.assetId);
        const earlierStartTimeProgram = props.results.actualStartTime < scheduleDetails.actualStartTime ? props.results : scheduleDetails;
        if (earlierStartTimeProgram.actualStartTime <= now && earlierStartTimeProgram.actualEndTime >= now) {
          scheduleDetails = earlierStartTimeProgram;
        }
      // If the endTime of the detection results asset is greater than current time i.e. it is live, then choose it
      } else if (isLiveDetectionResults){
        scheduleDetails = props.results;
      }
    }
    console.log("Live program: " + scheduleDetails.assetId);
  }

  const streamId = "" || props.stream.streamId;

  if(props.showLinear && (streamId.startsWith('esp-20') || streamId.startsWith('esp-30')) && streamId.split('-')[1].length === 5){
    return null;
  }

  if(props.showAventusLinear && (streamId.split('-')[1].length === 5 || streamId.startsWith('eurosport'))){
    return null;
  }

  if((streamId.split('-')[1].length === 5) && (streamId.startsWith('eurosport'))) {
    return null;
  }

  if(props.showAventus && ((streamId.split('-')[1].length < 5) || (!streamId.startsWith('esp-')))) {
    return null;
  }

  let liveStatus = '';
  if (now > scheduleDetails.actualEndTime)
    liveStatus = 'END';
  else if(now < scheduleDetails.actualStartTime)
    liveStatus = 'SCHEDULED';
  else if (scheduleDetails.broadcastType && scheduleDetails.broadcastType === 'Replay')
    liveStatus = 'REPLAY';
  else if (now >= scheduleDetails.actualStartTime && now <= scheduleDetails.actualEndTime)
    liveStatus = 'LIVE';

  if((liveStatus == "END" || liveStatus == "SCHEDULED" || liveStatus == "REPLAY") && props.showLive){
    return null;
  }

  if(props.showVirtual && (!("testChannel" in scheduleDetails) || !scheduleDetails.testChannel)) {
      return null;
  }
  if(!props.showVirtual && ("testChannel" in scheduleDetails && scheduleDetails.testChannel)) {
    return null;
  }
  const mosiacStreamLabel = CHANNEL_MAPPING[streamId] || streamId;
  return (<div className="mosaicChannelContainerWrapper  col-lg-6 col-xl-4 col-xxxl-3 col-xxxxl-2">
        <div className={`mb-4 mosaicChannelContainer ${hasError() ? 'channelErrorHighlight' : ''}`} onClick={() => {
          history.push(`/details?assetId=${scheduleDetails.assetId}&startTime=${scheduleDetails.actualStartTime}&endTime=${scheduleDetails.actualEndTime}`)
        }}>
          <ImageLoader signedImageUrlOperation={s3SignedUrlThumbnailOperation}
                       className={'mosaicChannelContainerImage'}/>
          <div className="mosaicChannelOverlay row">
            <div className="mosaicChannelTopbar">

              <div>
                <SchedulePlayState schedule={scheduleDetails}/>
                <p className="mosaicChannelLabel mosaicChannelHeader text-left">{mosiacStreamLabel!}</p>
                <p className="mosaicChannelLabel text-left">{scheduleDetails.assetName!}</p>
              </div>
              <p className="mosaicChannelLiveStatus">{liveStatus == 'END' &&
              <span className="badge bg-dark">End</span>}{liveStatus == 'SCHEDULED' &&
              <span className="badge bg-warning text-dark">Scheduled</span>}{liveStatus == 'LIVE' &&
              <span className="badge bg-success">Live</span>}{liveStatus == 'REPLAY' &&
              <span className="badge bg-primary text-light">REPLAY</span>}</p>
            </div>

            {liveStatus !== 'END' && <MoasaicStatusBar schedule={scheduleDetails} results={result}/>}
          </div>
          <div className="mosaicChannelTooltip">
            <p><b>Sport Type:</b><br/> {scheduleDetails.sportType}
            </p>
            <p><b>Start
              time:</b><br/> {scheduleDetails.actualStartTime && new Date(scheduleDetails.actualStartTime!).toISOString()}
            </p>
            <p><b>End
              time:</b><br/> {scheduleDetails.actualEndTime &&  new Date(scheduleDetails.actualEndTime!).toISOString()}
            </p>
            <p><b>Language tracks
              ({scheduleDetails.audio?.length}):</b><br/> {scheduleDetails.audio.map(a => a.languageName!).join(', ')}</p>
          </div>
        </div>
    </div>
  )
}
