import {combineReducers} from 'redux';
import {StateType} from 'typesafe-actions';
import {
    API_ADD_DETECTION_RESULT,
    API_ADD_SCHEDULE,
    API_ATTACH_IOT,
    API_ATTACH_IOT_ERROR,
    API_ATTACH_IOT_SUCCESS,
    API_GET_ALARM_PRESETS, API_GET_ALARM_PRESETS_ERROR, API_GET_ALARM_PRESETS_SUCCESS,
    API_GET_SCHEDULE_DETAILS,
    API_GET_SCHEDULE_DETAILS_ERROR,
    API_GET_SCHEDULE_DETAILS_SUCCESS,
    API_GET_SCHEDULE_RECORDS,
    API_GET_SCHEDULE_RECORDS_ERROR,
    API_GET_SCHEDULE_RECORDS_SUCCESS,
    API_GET_TODAY_SCHEDULES,
    API_GET_TODAY_SCHEDULES_ERROR,
    API_GET_TODAY_SCHEDULES_SUCCESS,
    API_POST_CONFIG,
    API_POST_CONFIG_ERROR,
    API_POST_CONFIG_SUCCESS,
    API_REMOVE_SCHEDULE,
    API_SEARCH,
    API_SEARCH_ERROR,
    API_SEARCH_SUCCESS,
    API_UPDATE_SCHEDULE
} from '../constants/api.constants';
import {DeepReadonlyObject} from '../types/reducers/DeepReadonly';
import {OperationalState, OperationState} from '../types/reducers/OperationStatus';
import {getStateError, getStateInitial, getStateInProgress, getStateSuccess} from '../utils/reducers/state.util';
import { apiActions } from '../actions/api.actions';
import DetectionResult from '../../types/DetectionResult';
import { NullableState } from '../types/reducers/NullableState';
import { Schedule } from '../../types/Stream';
import AlarmPreset from "../../types/AlarmPreset";
import Sport from '../../types/Sport';

export type APIState = {
	scheduleRecords: { [searchKey: string]: { type: 'details' | 'home', records: DetectionResult[], nextToken?: string } };
    postConfig: boolean;
    todaySchedules: { schedules: Schedule[]; detectionResults: { [k: string]: DetectionResult } };
    scheduleDetails: {
        [k: string]: Schedule
    };
    attachIoT: any;
    search: { items: any[], nextToken?: string };
    alarmPresets: {
        sports: Sport[],
        presets: AlarmPreset[]
    };
};

type APIStateOperational = Omit<OperationalState<APIState>, 'scheduleDetails' | 'scheduleRecords'> & { scheduleDetails: {
    [k: string]: OperationState<Schedule> | undefined
}, scheduleRecords: {
    [k: string]: OperationState<{ type: 'details' | 'home', records: DetectionResult[], nextToken?: string }> | undefined
}; };
type APIStateNullable = NullableState<APIStateOperational>;
type APIStateReadonly = DeepReadonlyObject<APIStateNullable>;

export const initialAPIState: APIStateNullable = {
	  scheduleRecords: {},
    postConfig: getStateInitial(),
    todaySchedules: getStateInitial({
        detectionResults: {},
        schedules: []
    }),
    scheduleDetails: {},
    attachIoT: getStateInitial(),
    search: getStateInitial({
        items: []
    }),
    alarmPresets: getStateInitial({
	      sports: [],
        presets: [],
    }),
};

export const apiReducers = combineReducers<APIStateReadonly, apiActions>({
	scheduleRecords: (state = initialAPIState.scheduleRecords, action) => {
        switch (action.type) {
            case API_GET_SCHEDULE_RECORDS: {
                // Don't use nextToken in reducer key, otherwise next page will create a new state entry instead of updating
                const { request: { assetId, type, options: { nextToken, ...restOptions } } } = action.payload;
                const searchKey = JSON.stringify({ assetId, type, ...restOptions });
                return {
                    ...state,
                    [searchKey]: {
                        ...getStateInProgress(),
                        current: state?.[searchKey]?.current
                    }
                };
            }
            case API_GET_SCHEDULE_RECORDS_SUCCESS: {
                const { request: { assetId, options: {nextToken, ...restOptions}, type }, response, isNextPage } = action.payload;
                const searchKey = JSON.stringify({ assetId, type, ...restOptions });
                const currentRecords = state?.[searchKey]?.current?.records;
                return {
                    ...state,
                    [searchKey]: getStateSuccess({
                        ...response,
                        records: isNextPage ? [ ...(currentRecords?.length ? currentRecords : []), ...response?.records] : response?.records,
                        type
                    })
                };
            }
            case API_GET_SCHEDULE_RECORDS_ERROR: {
                const { request: { assetId, options: {nextToken, ...restOptions}, type }, error } = action.payload;
                const searchKey = JSON.stringify({ assetId, type, ...restOptions });
                return {
                    ...state,
                    [searchKey]: {
                        ...getStateError(error),
                        current: state?.[searchKey]?.current
                    }
                };
            }
            case API_ADD_DETECTION_RESULT: {
                const {detectionResultToAdd} = action.payload;


                const obj = Object.keys(state!)?.filter((keyRecords) => {
                    const records = state?.[keyRecords]?.current?.records?.length;

                    return !!records;
                })?.map((keyRecords) => {
                    const current = state?.[keyRecords]?.current;
                    const currentRecords = current?.records;
                    const firstStateRecord = currentRecords?.[0];
                    const { assetId } = detectionResultToAdd;

                    return {
                        keyRecords,
                        ...state?.[keyRecords],
                        current: {
                            
                            type: current?.type!,
                            nextToken: current?.nextToken,
                            records: [
                                    ...(current?.type === 'home' ? (currentRecords?.length ? currentRecords : []) : []),
                                    // Insert the pushed schedule into state only if it matches the asset id of the records in state.
                                    ...(!!assetId && assetId === firstStateRecord?.assetId ? [detectionResultToAdd as DetectionResult] : []),
                                    ...(current?.type === 'details' ? (currentRecords?.length ? currentRecords : []) : []),
                                    
                                ],
                        }
                        
                    }
                }).reduce((prev, current) => {
                    let obj = prev;
                    const {
                        keyRecords,
                        ...rest

                    } = current;
                    obj[keyRecords] = rest; 
                    return prev;
                }, {} as any);

                return {
                    ...state,
                    ...obj
                };
            }
            default:
                return state;
            }
	},
    postConfig: (state = initialAPIState.postConfig, action) => {
        switch (action.type) {
            case API_POST_CONFIG: {
                return getStateInProgress();
            }
            case API_POST_CONFIG_SUCCESS: {
                return getStateSuccess(true);
            }
            case API_POST_CONFIG_ERROR: {
                const {error} = action.payload;
    
                return getStateError(error);
            }
            default:
                return state;
            }
	},
    todaySchedules: (state = initialAPIState.todaySchedules, action) => {
        switch (action.type) {
            case API_GET_TODAY_SCHEDULES: {
                return getStateInProgress();
            }
            case API_GET_TODAY_SCHEDULES_SUCCESS: {
                const {response} = action.payload;
    
                return getStateSuccess({
                    ...state?.current,
                    schedules: response.items
                });
            }
            case API_GET_TODAY_SCHEDULES_ERROR: {
                const {error} = action.payload;
    
                return getStateError(error);
            }
            case API_ADD_DETECTION_RESULT: {
                const {detectionResultToAdd} = action.payload;
                
                return getStateSuccess({
                    ...state?.current as any,
                    detectionResults: {
                        ...state?.current?.detectionResults,
                        [detectionResultToAdd.streamId as string]: detectionResultToAdd as DetectionResult
                    }
                });
            }
            case API_ADD_SCHEDULE: {
                const {scheduleToAdd} = action.payload;

                const found = state?.current?.schedules?.find(x => scheduleToAdd.assetId === x.assetId);

                if (!scheduleToAdd.streamId) {
                    return state;
                }

                if (found) {
                    return getStateSuccess({
                        ...state?.current as any,
                        schedules: [
                            ...(state?.current?.schedules?.length ? state?.current?.schedules : [] as Schedule[]),
                            ...state?.current?.schedules?.map((schedule) => {
                                if (schedule.assetId === scheduleToAdd.assetId) {
                                    return scheduleToAdd;
                                }
            
                                return schedule;
                            }) || []
                        ]
                    });
                } else {
                    return getStateSuccess({
                        ...state?.current as any,
                        schedules: [
                            ...(state?.current?.schedules?.length ? state?.current?.schedules : [] as Schedule[]),
                            scheduleToAdd
                        ]
                    });
                }
            }
            case API_REMOVE_SCHEDULE: {
                const {scheduleToRemove} = action.payload;

                return getStateSuccess({
                    ...state?.current,
                    schedules: state?.current?.schedules?.filter(x => scheduleToRemove.assetId !== x.assetId)
                });
            }
            default:
                return state;
            }
	},
    scheduleDetails: (state = initialAPIState.scheduleDetails, action) => {
        switch (action.type) {
            case API_GET_SCHEDULE_DETAILS: {
                const {request: { assetId }} = action.payload;
                return {
                    ...state,
                    [assetId]: getStateInitial<Schedule>()
                };
            }
            case API_GET_SCHEDULE_DETAILS_SUCCESS: {
                const {response, assetId } = action.payload;

                return {
                    ...state,
                    [assetId]: getStateSuccess(response)
                };
            }
            case API_GET_SCHEDULE_DETAILS_ERROR: {
                const {assetId, error} = action.payload;
                
                return {
                    ...state,
                    [assetId]: getStateError(error)
                };
            }
            case API_UPDATE_SCHEDULE: {
                const {scheduleToUpdate} = action.payload;
                
                return {
                    ...state,
                    [scheduleToUpdate?.assetId]: getStateSuccess(scheduleToUpdate as any)
                };
            }
            default:
                return state;
            }
	},
    attachIoT: (state = initialAPIState.attachIoT, action) => {
        switch (action.type) {
            case API_ATTACH_IOT: {
                return getStateInProgress();
            }
            case API_ATTACH_IOT_SUCCESS: {
                const {response} = action.payload;
    
                return getStateSuccess(response);
            }
            case API_ATTACH_IOT_ERROR: {
                const {error} = action.payload;
    
                return getStateError(error);
            }
            default:
                return state;
            }
	},
    search: (state = initialAPIState.search, action) => {
        switch (action.type) {
            case API_SEARCH: {
                const options = action?.payload?.request?.options as any;
                return {
                    ...getStateInProgress(),
                    current: options?.nextToken ? state?.current : undefined
                }
            }
            case API_SEARCH_SUCCESS: {
                const {response} = action.payload;
                return getStateSuccess({
                    nextToken: response.nextToken,
                    items: [
                        ...(state?.current?.items?.length ? state?.current?.items : []),
                        ...response.items
                    ]
                });
            }
            case API_SEARCH_ERROR: {
                const {error} = action.payload;
    
                return getStateError(error);
            }
            default:
                return state;
            }
	},
    alarmPresets: (state = initialAPIState.alarmPresets, action) => {
        switch (action.type) {
            case API_GET_ALARM_PRESETS: {
                return {
                    ...state,
                    ...getStateInProgress()
                };
            }
            case API_GET_ALARM_PRESETS_SUCCESS: {
                // @ts-ignore
                const {response} = action.payload;
                return {
                    ...state,
                    ...getStateSuccess(response)
                };
            }
            case API_GET_ALARM_PRESETS_ERROR: {
                const {error} = action.payload;
                return {
                    ...state,
                    ...getStateError(error),
                    current: state?.current
                };
            }
            default:
                return state;
        }
    },
});

export type apiReducers = StateType<typeof apiActions>;