import {combineReducers} from 'redux';
import {StateType} from 'typesafe-actions';

import {DeepReadonlyObject} from '../types/reducers/DeepReadonly';
import {OperationalState} from '../types/reducers/OperationStatus';
import {getStateError, getStateInitial, getStateInProgress, getStateSuccess} from '../utils/reducers/state.util';
import { NullableState } from '../types/reducers/NullableState';
import { IOT_CONNECT, IOT_CONNECT_ERROR, IOT_CONNECT_SUCCESS } from '../constants/iot.constants';
import { iotActions } from '../actions/iot.actions';

export type IoTState = {
	isConnected: boolean
};

type IoTStateOperational = OperationalState<IoTState>;
type IoTStateNullable = NullableState<IoTStateOperational>;
type IoTStateReadonly = DeepReadonlyObject<IoTStateNullable>;

export const initialIoTState: IoTStateNullable = {
	isConnected: getStateInitial()
};

export const iotReducers = combineReducers<IoTStateReadonly, iotActions>({
	isConnected: (state = initialIoTState.isConnected, action) => {
        switch (action.type) {
            case IOT_CONNECT: {
                return getStateInProgress();
            }
            case IOT_CONNECT_SUCCESS: {
                return getStateSuccess(true);
            }
            case IOT_CONNECT_ERROR: {
                const {error} = action.payload;
    
                return getStateError(error);
            }
            default:
                return state;
            }
	}
});

export type iotReducers = StateType<typeof iotActions>;