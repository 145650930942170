import {Schedule} from "../types/Stream";
import DetectionResult from "../types/DetectionResult";
import {FaCircle} from "react-icons/fa";
import {ISO639_2_ISO639_1} from "../constants";
import './MosaicStatusBar.css';
import StatusIcon from "./StatusIcon";

export default function MosaicStatusBar(props: { schedule: Schedule, results: DetectionResult }) {
  const { schedule, results } = props;
  let statuses;

  if(!results || !results.audio) {
    statuses = schedule.audio.map((a, i) => {
      return <div className="statusContainer" key={i.toString()}>
        <FaCircle className="mosaicChannelStatusIcon"/>
      </div>
    })
  } else if(results.ad){
    statuses = results.audio.map((a, i) => {
      return <div className="statusContainer" key={i.toString()}>
        <StatusIcon ad={results.ad} langTrack={a} isoCode={ISO639_2_ISO639_1[a.language]} source="mosaicView"/>
      </div>
    });
  }
  else {
    statuses = results.audio.map((a, i) => {
      return <div className="statusContainer" key={i.toString()}>
        <StatusIcon slate={results.slate} langTrack={a} isoCode={ISO639_2_ISO639_1[a.language]} source="mosaicView"/>
      </div>
    });
  }
  return <div className="mosaicChannelStatusBar">
    {statuses}
  </div>
}