import React, {useState} from 'react';
import './TextInput.css';

interface Props {
  placeholder?: string;
  label?: string;
  value?: string | number;
  onSubmit?: any,
  onChange?: any,
  style?: {},
}

export default function TextInput(props: Props) {
  const [value, setValue] = useState<string | number>(props.value ? props.value : '');

  const handleChange = (input) => {
    setValue(input);
    if (props.onChange !== undefined) {
      props.onChange(input);
    }
  };

  const handleChangeDone = () => {
    if (props.onSubmit !== undefined) {
      props.onSubmit(value);
    }
  };

  const { placeholder, label } = props;

  return (
    <div className="inputWrapper" style={props.style}>
      <p className="label">
        {label}
      </p>
      <input
        className="input"
        type="text"
        placeholder={ placeholder }
        //multiline = {false}
        value={value}
        onChange={e => handleChange(e.target.value)}
        onBlur={handleChangeDone}
      />
    </div>
  );
}
